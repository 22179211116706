<template>
    <div>
        <!-- 상단 (Mobile) -->
        <v-carousel
            v-if="$vuetify.breakpoint.mobile"
            :height="$vuetify.breakpoint.width/2.25"
            show-arrows-on-hover
            hide-delimiters
            cycle
            :interval="6000"
        >
            <v-carousel-item v-for="n in amount" :key="n">
                <!-- 배너 -->
                <BannerViewHome
                    :type="'home'+n+'_mobile'"
                    :width="$vuetify.breakpoint.width"
                    :height="$vuetify.breakpoint.width/2.25"
                />
            </v-carousel-item>
        </v-carousel>

        <!-- 상단 (PC) -->
        <v-carousel
            v-if="!$vuetify.breakpoint.mobile"
            height="430"
            show-arrows-on-hover
            hide-delimiter-background
            cycle
            :interval="6000"
        >
            <v-carousel-item v-for="n in amount" :key="n">
                <!-- 배너 -->
                <BannerViewHome
                    :type="'home'+n"
                    width="1920"
                    height="430"
                />
            </v-carousel-item>
        </v-carousel>

        <!-- 게시글 목록 -->
        <HomeList />
    </div>
</template>
<script>
import BannerViewHome from '@/components/BannerViewHome'
import HomeList from './home/components/HomeList'

export default {
    components: {
        BannerViewHome,
        HomeList
    },

    data: () => ({
        amount: 0,

        contents_list: [],
        community_list: [],

        share_id: "",
        share_nickname: "",
        dialog: {
            share: false
        }
    }),
    
    mounted(){
        this.$http.post("/api/admin/banner/import/home_banner_amount")
        .then((res) => {
            this.amount = res.data[0].amount
        })


        // 목록
        this.load()
    },

    watch: {
        dialog: {
            deep:true,
            handler(newData) {
                if(!newData.share){
                    this.share_id = ""
                }
            }
        }
    },

    methods: {
        // 목록
        load(){
            // 인기 콘텐츠 TOP 3
            this.$http.post("/api/home/contents/top3", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id
                }
            }).then((res) => {
                if(res.data.length){
                    this.contents_list = res.data

                    // 썸네일 추가
                    this.matchThumbnail(this.contents_list)
                }
            })

            // 커뮤니티 인기글 TOP 3
            this.$http.post("/api/home/community/top3", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id
                }
            }).then((res) => {
                if(res.data.length){
                    this.community_list = res.data

                    // 썸네일 추가
                    this.matchThumbnail(this.community_list)
                }
            })
        },

        // 썸네일 추가
        matchThumbnail(list) {
            // <img> 태그용 정규식
            const imgReg = /<img[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
            // CustomImage 태그용 정규식 (대소문자 구분 없음)
            const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;

            list.forEach(e => {
                if (typeof e.content === 'string') {
                    const imgMatch = e.content.match(imgReg);
                    const customImgMatch = e.content.match(customImgReg);

                    if (customImgMatch) {
                        // CustomImage 태그에서 src 추출
                        e.image = customImgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if (imgMatch) {
                        // <img> 태그에서 src 추출
                        e.image = imgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                }
            });
        },

        // 게시글로 이동
        read(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 읽기는 로그인 후 이용가능합니다.")
            }else{
                this.$router.push("/"+item.board + "?type=read&id=" + item.id)
            }
        },

        // 스크랩하기
        scrap(item){
            if(item.is_scraped == 0){
                this.$http.post("/api/board/insert/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }else{
                this.$http.post("/api/board/delete/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }
        },

        // 공유하기 열기
        share_open(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 공유는 로그인 후 이용가능합니다.")
            }else{
                this.share_id = item.id
                this.dialog.share = true
            }
        },

        // 공유하기 제출
        share_submit(){
            if(this.share_nickname == ""){
                alert("공유할 대상의 닉네임을 입력해주세요")
            }else{
                this.$http.post("/api/board/share/checkNickname", {
                    params: {
                        nickname: this.share_nickname
                    }
                }).then((res) => {
                    if(!res.data.length){
                        alert("입력하신 닉네임에 해당하는 사용자를 찾지 못하였습니다.\n닉네임을 다시 확인해주세요.")
                    }else if(res.data[0].user_id == this.$store.state.hyundai_user.user_id){
                        alert("자신에게 게시글 공유하기를 할 수 없습니다.")
                    }else{
                        this.$http.post("/api/board/share/message/insert", {
                            params: {
                                sender: this.$store.state.hyundai_user.user_id,
                                receiver: res.data[0].user_id,
                                post_id: this.share_id
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                alert("해당 게시글이 상대에게 공유되었습니다.")
                                this.dialog.share = false
                            }
                        })
                    }
                })
            }
        }
    }
}
</script>