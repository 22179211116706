<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">배너 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-4 pb-16">
            <!-- 홈 배너 설정 -->
            <p class="text-h5 font-weight-bold mb-2">홈 배너 설정</p>

            <v-sheet
                class="mt-2 pa-4"
                outlined
                width="280"
            >
                <p class="mb-2 ml-2px font-weight-medium">홈 배너 갯수 설정</p>
                <v-text-field
                    v-model="amount"
                    outlined
                    dense
                    hide-details
                    class="rounded-5 mb-2"
                    suffix="개"
                ></v-text-field>
                <v-btn
                    @click="updateAmount()"
                    depressed block dark color="admin lighten-2"
                >
                    배너 설정하기
                </v-btn>
            </v-sheet>

            <BannerSetting v-if="banner_amount >= 1" class="mt-2" title="홈 배너1" type="home1" width="960" height="215" />
            <BannerSetting v-if="banner_amount >= 1" class="mt-2" title="홈 배너1_모바일" type="home1_mobile" width="540" height="240" />
            <BannerSetting v-if="banner_amount >= 2" class="mt-2" title="홈 배너2" type="home2" width="960" height="215" />
            <BannerSetting v-if="banner_amount >= 2" class="mt-2" title="홈 배너2_모바일" type="home2_mobile" width="540" height="240" />
            <BannerSetting v-if="banner_amount >= 3" class="mt-2" title="홈 배너3" type="home3" width="960" height="215" />
            <BannerSetting v-if="banner_amount >= 3" class="mt-2" title="홈 배너3_모바일" type="home3_mobile" width="540" height="240" />
            <BannerSetting v-if="banner_amount >= 4" class="mt-2" title="홈 배너4" type="home4" width="960" height="215" />
            <BannerSetting v-if="banner_amount >= 4" class="mt-2" title="홈 배너4_모바일" type="home4_mobile" width="540" height="240" />
            <BannerSetting v-if="banner_amount >= 5" class="mt-2" title="홈 배너5" type="home5" width="960" height="215" />
            <BannerSetting v-if="banner_amount >= 5" class="mt-2" title="홈 배너5_모바일" type="home5_mobile" width="540" height="240" />
            <BannerSetting v-if="banner_amount >= 6" class="mt-2" title="홈 배너6" type="home6" width="960" height="215" />
            <BannerSetting v-if="banner_amount >= 6" class="mt-2" title="홈 배너6_모바일" type="home6_mobile" width="540" height="240" />

            <!-- GENRE 배너 설정 -->
            <p class="text-h5 font-weight-bold mt-10 mb-2">GENRE 배너 설정</p>
            <BannerSetting class="mt-2" title="GENRE 배너" type="genre" width="960" height="120" />
            <BannerSetting class="mt-2" title="GENRE 배너_모바일" type="genre_mobile" width="540" height="200" />

            <!-- MOOD 배너 설정 -->
            <p class="text-h5 font-weight-bold mt-10 mb-2">MOOD 배너 설정</p>
            <BannerSetting class="mt-2" title="MOOD 배너" type="mood" width="960" height="120" />
            <BannerSetting class="mt-2" title="MOOD 배너_모바일" type="mood_mobile" width="540" height="200" />

            <!-- COMMUNITY 배너 설정 -->
            <p class="text-h5 font-weight-bold mt-10 mb-2">COMMUNITY 배너 설정</p>
            <BannerSetting class="mt-2" title="COMMUNITY 배너" type="community" width="960" height="120" />
            <BannerSetting class="mt-2" title="COMMUNITY 배너_모바일" type="community_mobile" width="540" height="200" />

            <!-- AUTH 배너 설정 -->
            <p class="text-h5 font-weight-bold mt-10 mb-2">AUTH 배너 설정</p>
            <BannerSetting class="mt-2" title="AUTH 배너" type="auth" width="960" height="100" />
            <BannerSetting class="mt-2" title="AUTH 배너_모바일" type="auth_mobile" width="540" height="200" />

            <!-- MY 배너 설정 -->
            <p class="text-h5 font-weight-bold mt-10 mb-2">MY 배너 설정</p>
            <BannerSetting class="mt-2" title="MY 배너" type="my" width="960" height="120" />
            <BannerSetting class="mt-2" title="MY 배너_모바일" type="my_mobile" width="540" height="200" />

            <!-- 개인정보처리방침 배너 설정 -->
            <p class="text-h5 font-weight-bold mt-10 mb-2">개인정보처리방침 배너 설정</p>
            <BannerSetting class="mt-2" title="개인정보처리방침 배너" type="privacy" width="960" height="120" />
            <BannerSetting class="mt-2" title="개인정보처리방침 배너_모바일" type="privacy_mobile" width="540" height="200" />

            <!-- 사이트검색 배너 설정 -->
            <p class="text-h5 font-weight-bold mt-10 mb-2">사이트검색 배너 설정</p>
            <BannerSetting class="mt-2" title="사이트검색 배너" type="search" width="960" height="120" />
            <BannerSetting class="mt-2" title="사이트검색 배너_모바일" type="search_mobile" width="540" height="200" />
        </v-sheet>
    </div>
</template>
<script>
import BannerSetting from '@/components/BannerSetting'

export default {
    components: {
        BannerSetting
    },

    data: () => ({
        amount: 0,
        banner_amount: 0
    }),

    mounted() {
        this.$http.post("/api/admin/banner/import/home_banner_amount")
        .then((res) => {
            this.amount = res.data[0].amount
            this.banner_amount = res.data[0].amount
        })
    },

    methods: {
        updateAmount(){
            this.$http.post("/api/admin/banner/update/home_banner_amount", {
                params: {
                    amount: this.amount
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.banner_amount = this.amount
                    alert("홈 배너 갯수가 변경되었습니다.")
                }
            })
        }
    }
}
</script>