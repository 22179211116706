<template>
    <v-sheet class="pa-4">
        <table class="table_style">          
            <tr>
                <td class="ma-0 pa-0" style="background:white; text-align:center;">
                    <v-sheet class="d-flex justify-center py-10 px-4">
                        <v-sheet max-width="200" max-heigth="200">
                            <v-img :src="'/upload/user/profile_image/' + user.profile_image"></v-img>
                        </v-sheet>
                    </v-sheet>
                </td>
            </tr>           
            <tr>
                <td class="py-2 px-3" style="background:white;">
                    <v-file-input
                        placeholder="업로드할 이미지를 선택해주세요"
                        outlined
                        dense
                        show-size
                        hide-details
                        prepend-icon
                        color="blue"
                        v-model="uploadImage"
                        @change="updated()"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
                        </template>
                    </v-file-input>
                </td>
            </tr>  
            <tr>
                <td class="py-2 px-3">
                    <v-btn
                        @click="imageUpload"
                        depressed block dark color="primary"
                    >
                        이미지 등록하기
                    </v-btn>
                </td>
            </tr>
        </table>
    </v-sheet>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    data: () => ({
        uploadImage: {},
        user: {},
        url: ""
    }),

    mounted(){
        // 유저 정보 불러오기
        this.$http.post('/api/mypage/update/load', {
            params: {
                user_id: this.$store.state.hyundai_user.user_id
            }
        }).then((res) => {
            this.user = res.data[0]
        })
    },

    methods: {
        ...mapMutations(['update_profile_image']),

        updated(){
            this.url = URL.createObjectURL(this.uploadImage)
        },

        // 프로필 이미지 변경
        imageUpload: _.debounce(function() {
            var file = this.uploadImage
            this.url = URL.createObjectURL(file)
            var formData = new FormData()
            formData.append("image", file)
            this.$http.post('/api/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    type: "profile_image"
                }
            }).then((res) => {
                this.user.profile_image = res.data
                this.$http.post('/api/mypage/update/profile_image', {
                    params: {
                        change_user_img: this.user.profile_image,
                        user_id: this.$store.state.hyundai_user.user_id
                    }
                }).then((res) => {
                    if(res.data){
                        this.update_profile_image(this.user.profile_image)
                        this.$emit("imageUpdated")
                        alert("프로필 이미지가 성공적으로 변경되었습니다.")
                    }else{
                        alert("프로필 이미지 변경에 실패하였습니다. 고객센터에 문의바랍니다.")
                        return (false)
                    }
                })
            })
        }, 500)
    }
}
</script>
<style scoped>
.table_style{
    max-width:340px;
    border:1px solid #ccc;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ccc;
    padding:10px;
}

</style>