<template>
    <div>
        <!-- 배너 (Mobile) -->
        <BannerViewBoard
            v-if="$vuetify.breakpoint.mobile"
            type="genre_mobile"
            :width="$vuetify.breakpoint.width"
            :height="$vuetify.breakpoint.width/2.7"
            title="GENRE"
            subtitle="문화ㆍ예술계의 이슈를 장르별로 소개합니다"
        />

        <!-- 배너 (PC) -->
        <BannerViewBoard
            v-if="!$vuetify.breakpoint.mobile"
            type="genre"
            width="1920"
            height="240"
            title="GENRE"
            subtitle="문화ㆍ예술계의 이슈를 장르별로 소개합니다"
        />
    
        <!-- 본문 -->
        <div>
            <!-- 목록 -->
            <div v-if="$route.query.type == 'list'">
                <BoardList />
            </div>

            <!-- 작성 -->
            <div v-if="$route.query.type == 'write'">
                <BoardWrite />
            </div>

            <!-- 읽기 -->
            <div v-if="$route.query.type == 'read'">
                <BoardRead />
            </div>

            <!-- 수정 -->
            <div v-if="$route.query.type == 'update'">
                <BoardUpdate />
            </div>
        </div>
    </div>
</template>
<script>
import BannerViewBoard from '@/components/BannerViewBoard'
import BoardList from '@/components/board/list'
import BoardWrite from '@/components/board/write'
import BoardRead from '@/components/board/read'
import BoardUpdate from '@/components/board/update'

export default {
    components: {
        BannerViewBoard,
        BoardList,
        BoardWrite,
        BoardRead,
        BoardUpdate
    }
}
</script>