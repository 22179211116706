<template>
    <v-sheet
        width="1000"
        class="pa-4 mt-2 rounded-10 mx-auto"
        style="position:relative;"
    >
        <!-- 페이지 제목 -->
        <p class="text-h5 text-center font-weight-medium">
            게시글 수정
        </p>

        <!-- 임시저장 -->
        <div
            style="position:absolute; top:20px; right:16px;"
        >
            <v-btn
                small
                outlined
                color="green"
                class="rounded-lg font-weight-regular mr-2"
                @click="save()"
            >
                임시저장
            </v-btn>
            <v-btn
                small
                outlined
                color="grey lighten-1"
                class="rounded-lg font-weight-regular"
                @click="saveImport()"
            >
                불러오기
            </v-btn>
        </div>

        <!-- 카테고리, 제목 -->
        <v-sheet class="mb-2 d-flex font-weight-bold">
            <!-- 카테고리 -->
            <v-sheet 
                width="300"
                class="pa-1 mr-2 rounded-10"
                outlined
            >
                <v-select
                    hide-details
                    dense
                    solo
                    flat
                    placeholder="카테고리"
                    :items="category_list"
                    v-model="category"
                ></v-select>
            </v-sheet>

            <!-- 제목 -->
            <v-sheet
                width="100%"
                class="pa-1 rounded-10"
                outlined
            >
                <v-textarea
                    dense
                    solo
                    flat
                    hide-details
                    :rows="rows"
                    max-height="200"
                    placeholder="제목을 입력해주세요"
                    v-model="title"
                    @input="updateRows"
                ></v-textarea>
            </v-sheet>
        </v-sheet>

        <!-- 부제목 -->
        <v-sheet
            class="pa-1 pb-2 rounded-10"
            outlined
        >
            <v-textarea
                solo
                flat
                hide-details
                rows="2"
                placeholder="부제목을 입력해주세요"
                v-model="subtitle"
            ></v-textarea>
        </v-sheet>

        <!-- 본문 -->
        <TipTapWriter
            v-if="options.content"
            class="tiptap_style mt-2"
            :options="options"
            :key="componentKey"
        />

        <!-- 첨부파일 -->
        <v-sheet
            v-if="attachment"
            class="mt-3 mb-6"
        >
            <v-btn
                depressed
                class="py-6 rounded-10"
            >
                <v-icon size="20" class="mt-1 mr-2" color="primary">mdi-file</v-icon>
                {{attachment}}
            </v-btn>
            <v-btn
                class="ml-1"
                icon
                @click="attachment = ''"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-sheet>

        <!-- 파일 첨부 -->
        <v-file-input
            outlined
            show-size
            hide-details
            prepend-icon
            class="rounded-lg mt-4 mb-6"
            label="파일 첨부"
            v-model="uploadFile"
        >
            <template v-slot:prepend-inner>
                <v-icon class="mr-1" color="grey darken-4">mdi-file-plus</v-icon>
            </template>
        </v-file-input>

        <!-- 버튼 -->
        <v-sheet class="d-flex justify-center mt-6">
            <v-btn
                class="mx-2 px-10 rounded-10"
                color="#ADAFCA"
                dark
                large
                depressed
                @click="cancel()"
            >
                취소하기
            </v-btn>
            <v-btn
                class="mx-2 px-10 rounded-10"
                color="primary"
                dark
                large
                depressed
                @click="submit()"
            >
                수정하기
            </v-btn>
        </v-sheet>
    </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        TipTapWriter
    },

    data: () => ({
        uploadFile: {},
        category_list: [],
        category: '',
        writer: '',
        title: '',
        subtitle: '',
        attachment: '',

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0,

        rows: 1
    }),

    mounted(){
        // 카테고리 목록 불러오기
        this.$http.post('/api/board/category/select/specific', {
            params: {
                board : this.$route.path.slice(1)
            }
        }).then(res => {
            this.category_list = res.data.map(a => a.category)
        })

        // 게시글 정보 불러오기
        this.$http.post('/api/board/select/specific', {
            params: {
                id: this.$route.query.id
            }
        }).then((res) => {
            this.category = res.data[0].category
            this.title = res.data[0].title
            this.subtitle = res.data[0].subtitle
            this.options.content = res.data[0].content
            this.attachment = res.data[0].attachment

            this.writer = res.data[0].writer

            if(this.writer != this.$store.state.hyundai_user.user_id){
                if($store.state.hyundai_user.type != '관리자'){
                    alert("자신의 글 외에는 수정하실 수 없습니다.")
                    this.$router.go(-1)
                }
            }
        })
    },

    methods: {
        // 줄 확대
        updateRows() {
            const lineBreaks = this.title.split(/\r\n|\r|\n/).length
            this.rows = lineBreaks
        },

        // 임시저장
        save(){
            if(confirm("이전에 임시저장하신 게시글이 있다면 덮어쓰워집니다.\n임시저장하시겠습니까?")){
                if(!this.title.length)
                {
                    alert("제목을 입력해주세요")
                }
                else if(!this.subtitle.length)
                {
                    alert("부제목을 입력해주세요")
                }
                else if(!this.options.content.length)
                {
                    alert("내용을 입력해주세요")
                }
                else
                {
                    this.$http.post("/api/board/save/insert", {
                        params: {
                            writer: this.$store.state.hyundai_user.user_id,
                            title: this.title,
                            subtitle: this.subtitle,
                            content: this.options.content
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            alert("임시저장되었습니다.")
                        }
                    })
                }
            }
        },

        // 불러오기
        saveImport(){
            if(confirm("작성하고 계신 내용이 덮어씌워집니다.\n임시저장을 불러오시겠습니까?")){
                this.$http.post("/api/board/save/import", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                    }
                }).then((res) => {
                    if(!res.data.length){
                        alert("임시저장한 게시글이 없습니다.")
                    }else{
                        this.title = res.data[0].title
                        this.subtitle = res.data[0].subtitle
                        this.options.content = res.data[0].content
                        this.componentKey++
                    }
                })
            }
        },

        // 취소하기
        cancel(){
            if(confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")){
                this.$router.go(-1)
            }
        },

        submit(){
            if(!this.category.length)
            {
                alert("카테고리를 선택해주세요")
            }
            else if(!this.title.length)
            {
                alert("제목을 입력해주세요")
            }
            else if(!this.subtitle.length)
            {
                alert("부제목을 입력해주세요")
            }
            else if(!this.options.content.length)
            {
                alert("내용을 입력해주세요")
            }
            else
            {
                // 파일 첨부 X
                if(!this.uploadFile.name)
                {
                    this.$http.post('/api/board/update', {
                        params: {
                            category: this.category,
                            title: this.title,
                            subtitle: this.subtitle,
                            content: this.options.content,
                            attachment: this.attachment,
                            id: this.$route.query.id
                        }
                    }).then(res => {
                        if(res.data.affectedRows){
                            this.$router.push(this.$route.path + "?type=read&id=" + this.$route.query.id)
                        }
                    })
                }

                // 파일 첨부 O
                else if(this.uploadFile.name)
                {
                    var file = this.uploadFile
                    var formData = new FormData()
                    formData.append("image", file)
                    this.$http.post('/api/image/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "board/attachment"
                        }
                    }).then((res_attachment) => {
                        this.$http.post('/api/board/update', {
                            params: {
                                category: this.category,
                                title: this.title,
                                subtitle: this.subtitle,
                                content: this.options.content,
                                attachment: res_attachment.data,
                                id: this.$route.query.id
                            }
                        }).then(res => {
                            if(res.data.affectedRows){
                                this.$router.push(this.$route.path + "?type=read&id=" + this.$route.query.id)
                            }
                        })
                    })
                }
            }
        }
    }
}
</script>
<style scoped>
.table_style{
    width:100%;
    background:white;
    border:1px solid #ddd;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ddd;
    padding:4px;
    text-align: center;
}

.filled{
    background:#ddd;
    color:#999;
    font-weight:300;
}

.reservation{
    background:#bba8ca;
    color:#84679a;
    font-weight:300;
}
</style>