<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">카테고리 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-list expand>
                <!-- Board 그룹 내 Board 목록 -->
                <v-list-item
                    dense
                    v-for="board in boards"
                    :key="board.id"
                    exact
                >
                    <v-list-item-title class="font-weight-light d-flex align-center pa-2">
                        <!-- 게시판 명 -->
                        <font style="width:200px;" class="mr-8 text-uppercase font-weight-medium">{{board.title}}</font>

                        <!-- 게시판 내 카테고리 목록 -->
                        <v-card class="d-flex align-center px-1" outlined style="width:100%; height:60px;">
                            <v-chip
                                class="rounded-15 mx-1"
                                style="border:1px solid #777;"
                                outlined
                                label
                                v-for="item in board.categorys"
                                :key="item"
                            >
                                <span>{{item.category}}</span>
                            </v-chip>
                        </v-card>
                        <v-spacer></v-spacer>

                        <!-- 버튼 -->
                        <v-btn
                            height="60"
                            class="ml-4 px-8"
                            color="admin lighten-2"
                            dark
                            depressed
                            @click="open_update(board)"
                        >
                            <v-icon small left>mdi-database-check-outline</v-icon>
                            수정하기
                        </v-btn>
                        <v-btn
                            height="60"
                            class="ml-4 px-8"
                            color="admin lighten-2"
                            dark
                            depressed
                            @click="open_delete(board)"
                        >
                            <v-icon small left>mdi-database-remove-outline</v-icon>
                            삭제하기
                        </v-btn>
                        <v-btn
                            height="60"
                            class="ml-4 px-8"
                            color="admin lighten-2"
                            dark
                            depressed
                            @click="open_create(board)"
                        >
                            <v-icon small left>mdi-database-plus-outline</v-icon>
                            추가하기
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-sheet>

        <!-- 수정하기 (Dialog) -->
        <v-dialog v-model="dialog.update" width="auto">
            <v-sheet class="pa-2">
                <v-sheet>
                    <table class="updatetable">
                        <tr>
                            <th style="width:300px;">카테고리</th>
                            <th style="width:140px;">수정</th>
                            <th style="width:100px;">순서변경</th>
                        </tr>
                        <tr v-for="item in selected.categorys" :key="'update'+item.id">
                            <td style="width:300px;">{{item.category}}</td>
                            <td style="width:140px;">
                                <v-btn
                                    color="admin lighten-2"
                                    dark
                                    block
                                    depressed
                                    @click="input_update(item)"
                                >
                                    수정
                                </v-btn>
                            </td>
                            <td style="width:100px;">
                                <v-btn
                                    class="mb-2"
                                    color="admin lighten-2"
                                    small
                                    dark
                                    block
                                    depressed
                                    @click="change_sequence_up(item)"
                                >
                                    <v-icon>mdi-chevron-up</v-icon>
                                </v-btn>
                                <v-btn
                                    color="admin lighten-2"
                                    small
                                    dark
                                    block
                                    depressed
                                    @click="change_sequence_down(item)"
                                >
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </table>

                    <!-- 수정하기 상세 (Dialog) -->
                    <v-dialog v-model="dialog.update_detail" width="auto">
                        <v-card>
                            <table class="updatetable" style="height:120px;">
                                <tr>
                                    <th style="width:300px;">기존 값</th>
                                    <th style="width:300px;">수정 값</th>
                                </tr>
                                <tr>
                                    <td style="width:300px;">{{update_oldValue}}</td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            v-model="update_newValue"
                                        ></v-text-field>
                                    </td>
                                </tr>
                            </table>
                            <v-sheet class="mt-2 pa-2 d-flex justify-center">
                                <v-btn
                                    class="px-6 mr-4"
                                    color="admin lighten-2"
                                    large
                                    dark
                                    depressed
                                    @click="submit_update()"
                                >
                                    <v-icon small left>mdi-database-edit-outline</v-icon>
                                    수정하기
                                </v-btn>
                                <v-btn
                                    class="px-6"
                                    color="grey"
                                    large
                                    dark
                                    depressed
                                    @click="dialog.update_detail = false"
                                >
                                    <v-icon small left>mdi-cancel</v-icon>
                                    취소하기
                                </v-btn>
                            </v-sheet>
                        </v-card>
                    </v-dialog>
                </v-sheet>
                <v-sheet class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        class="px-12"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.update = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        닫기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 삭제하기 (Dialog) -->
        <v-dialog v-model="dialog.delete" width="auto">
            <v-sheet class="pa-2">
                <v-sheet>
                    <table class="updatetable">
                        <tr>
                            <th style="width:300px;">카테고리</th>
                            <th style="width:140px;">삭제</th>
                        </tr>
                        <tr v-for="item in selected.categorys" :key="'delete'+item.id">
                            <td style="width:300px;">{{item.category}}</td>
                            <td style="width:140px;">
                                <v-btn
                                    color="admin lighten-2"
                                    dark
                                    block
                                    depressed
                                    @click="submit_delete(item.id)"
                                >
                                    삭제
                                </v-btn>
                            </td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        class="px-12"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.delete = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        닫기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 추가하기 (Dialog) -->
        <v-dialog v-model="dialog.create" width="auto">
            <v-sheet class="pa-2">
                <v-sheet>
                    <table class="updatetable">
                        <tr>
                            <th style="width:300px;">추가할 카테고리</th>
                        </tr>
                        <tr>
                            <td style="width:300px;">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="create_newValue"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        class="px-6 mr-4"
                        color="admin lighten-2"
                        large
                        dark
                        @click="submit_create()"
                        depressed
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        추가하기
                    </v-btn>
                    <v-btn
                        class="px-6"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.create=false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        boards: [
            { title: "genre", categorys: [] },
            { title: "mood", categorys: [] },
            { title: "community", categorys: [] }
        ],

        dialog: {
            update: false,
            delete: false,
            create: false,

            update_detail: false,
        },

        selected: [],

        update_board: "",
        update_id: "",
        update_oldValue: "",
        update_newValue: "",

        create_board: "",
        create_newValue: ""
    }),

    mounted () {
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.boards.forEach(item => {
                this.$http.post('/api/admin/category/select', {
                    params: {
                        board: item.title
                    }
                }).then(res => {
                    item.categorys = res.data
                })
            })
        },

        // 수정하기 열기
        open_update(board){
            this.dialog.update = true
            this.selected = board
        },

        // 수정하기 값 입력창 열기
        input_update(item){
            this.dialog.update_detail = true
            this.update_board = item.board
            this.update_id = item.id
            this.update_oldValue = item.category
            this.update_newValue = item.category
        },

        // 수정하기 제출
        submit_update(){
            this.$http.post('/api/admin/category/update', {
                params: {
                    id : this.update_id,
                    board : this.update_board,
                    oldValue : this.update_oldValue,
                    newValue : this.update_newValue
                }
            }).then(res => {
                alert("해당 카테고리가 수정되었습니다.")
                this.$router.go(this.$router.currentRoute)
            })
        },

        // 삭제하기 열기
        open_delete(board){
            this.dialog.delete = true
            this.selected = board
        },
        
        // 삭제하기 제출
        submit_delete(id){
            this.$http.post('/api/admin/category/delete', {
                params: {
                    id : id
                }
            }).then(res => {
                alert("해당 카테고리가 삭제되었습니다")
                this.$router.go(this.$router.currentRoute)
            })
        },

        // 추가하기 열기
        open_create(board){
            this.dialog.create = true
            this.create_board = board.title
        },

        // 추가하기 제출
        submit_create(){
            this.$http.post('/api/admin/category/create', {
                params: {
                    board : this.create_board,
                    newValue : this.create_newValue
                }
            }).then(res => {
                alert("해당 카테고리가 추가되었습니다")
                this.$router.go(this.$router.currentRoute)
            })
        },

        // 카테고리 순서 변경 (UP)
        change_sequence_up(item){
            this.$http.post('/api/admin/category/change/sequence/find/prev', {
                params: {
                    id: item.id,
                    board: item.board
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("첫번째 카테고리입니다.")
                }else{
                    this.$http.post('/api/admin/category/change/sequence/swap', {
                        params: {
                            title: item.category,
                            title2: res.data[0].category
                        }
                    }).then(() => {
                        alert("카테고리 순서가 변경되었습니다.\n("+item.category+" <->"+res.data[0].category+")")
                        this.load()
                        this.dialog.update = false
                    })
                }
            })
        },

        // 카테고리 순서 변경 (DOWN)
        change_sequence_down(item){
            this.$http.post('/api/admin/category/change/sequence/find/next', {
                params: {
                    id: item.id,
                    board: item.board
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("마지막 카테고리입니다.")
                }else{
                    this.$http.post('/api/admin/category/change/sequence/swap', {
                        params: {
                            title: item.category,
                            title2: res.data[0].category
                        }
                    }).then(() => {
                        alert("카테고리 순서가 변경되었습니다.\n("+res.data[0].category+" <->"+item.category+")")
                        this.load()
                        this.dialog.update = false
                    })
                }
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>