<template>
    <div>
        <!-- 배너 (Mobile) -->
        <BannerViewAuth
            v-if="$vuetify.breakpoint.mobile"
            type="auth_mobile"
            :width="$vuetify.breakpoint.width"
            :height="$vuetify.breakpoint.width/2.7"
            title="회원가입"
        />

        <!-- 배너 (PC) -->
        <BannerViewAuth
            v-if="!$vuetify.breakpoint.mobile"
            type="auth"
            width="1920"
            height="200"
            title="회원가입"
        />

        <!-- 본문 -->
        <v-sheet class="pt-16 pb-16 rounded-10">
            <v-sheet class="px-8 py-16 mx-auto rounded-lg" width="480" outlined>
                <!-- 문구 -->
                <v-sheet color="transparent" width="400" class="mx-auto">
                    <div class="d-flex mx-auto mb-8">
                        <v-img
                            height="140"
                            width="200"
                            contain
                            src="@/assets/logo/vertical_default.svg"
                        ></v-img>
                    </div>
                    <p class="text-center text-subtitle-1 font-weight-medium mt-2 grey--text text--darken-2">
                        회원가입
                    </p>
                    <p class="text-center text-caption grey--text text--darken-1">
                        필수 이용약관 확인 후 동의해주시기 바랍니다
                    </p>
                </v-sheet>

                <v-card-text class="mt-2 px-4">
                    <v-sheet class="d-flex ml-8 mr-6">
                        <v-checkbox
                            style="padding-top:3px !important"
                            class="ma-0 pa-0"
                            color="primary"
                            v-model="privacy"
                            :error-messages="privacy_Errors"
                            label="개인정보처리방침"
                            required
                            @change="$v.privacy.$touch()"
                            @blur="$v.privacy.$touch()"
                        ></v-checkbox>
                        <v-spacer></v-spacer>
                        <router-link to="/policy/privacy?type=read" style="cursor:pointer; text-decoration:none !important;">
                            <p class="text-subtitle-2 font-weight-bold mt-3px mr-3 primary--text">
                                약관보기
                                <v-icon size="20" class="ml-1 mb-2px" color="primary">
                                    mdi-open-in-new
                                </v-icon>
                            </p>
                        </router-link>
                    </v-sheet>
                </v-card-text>
                <v-sheet class="d-flex justify-center mt-6">
                    <v-btn
                        class="px-16 rounded-lg"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="submit()"
                    >
                        진행하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import BannerViewAuth from '@/components/BannerViewAuth'
import { validationMixin } from 'vuelidate'

export default {
    components: {
        BannerViewAuth
    },
    
    mixins: [validationMixin],

    validations: {
        privacy: {
            checked (val) {
                return val
            }
        }
    },

    data: () => ({
        privacy:false,
        submitStatus: null
    }),

    computed: {
        privacy_Errors () {
            const errors = []
            if (!this.$v.privacy.$dirty) return errors
            !this.$v.privacy.checked && errors.push('개인정보처리방침에 동의해주세요')
            return errors
        }
    },

    methods: {
        submit () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.$router.push('/auth/join/form')
            }
        }
    }
}
</script>
<style>
  .v-input--checkbox .v-label {
    font-size: 15px;
    font-weight: bold;
  }
</style>