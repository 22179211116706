<template>
    <div>
        <v-icon color="grey" size="24" class="mb-6px">mdi-comment-text-outline</v-icon>
        <span class="ml-2 text-h6">작성 댓글</span>
        <v-divider class="mt-3 mb-8"></v-divider>

        <v-data-table
            v-if="list.length"
            :items="list"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
        >
            <template v-slot:item="{ item }">
                <tr 
                    class="pa-0 ma-0" 
                    style="cursor:pointer;" 
                    @click="read(item)"
                >
                    <td>
                        <v-sheet class="d-flex justify-space-between" color="transparent">
                            <!-- 공유 -->
                            <v-sheet
                                :width="$vuetify.breakpoint.mobile? '75%':600"
                                color="transparent"
                                class="text-truncate"
                            >
                                <p
                                    class="my-3 d-flex"
                                >
                                    <v-icon color="grey" size="16">mdi-comment-text-outline</v-icon>
                                    <span class="ml-4 font-weight-medium">{{item.content}}</span>
                                </p>
                            </v-sheet>

                            <!-- 쪽지발송일 -->
                            <v-sheet class="d-flex align-center" color="transparent">
                                <font class="ml-2 grey--text text-caption">
                                    {{
                                        new Date().toLocaleDateString() == new Date(item.created).toLocaleDateString()?
                                        new Date(item.created).toLocaleTimeString() : new Date(item.created).toLocaleDateString()
                                    }}
                                </font>
                            </v-sheet>
                        </v-sheet>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-sheet
            v-if="!list.length"
            class="py-16 mb-8 text-center"
        >
            <v-icon size="60" color="grey lighten-1" class="mb-3">mdi-comment-text-outline</v-icon>
            <p class="grey--text text--lighten-1">작성하신 댓글이 없습니다</p>
        </v-sheet>

        <!-- 페이징 -->
        <v-pagination
            class="mt-4"
            v-model="page"
            :length="1"
            :total-visible="7"
        ></v-pagination>
    </div>
</template>
<script>
export default {
    data: () => ({
        // 검색
        select_list: {
            search_type: ["전체", "글쓴이", "제목", "내용"]
        },
        search_type: "전체",
        keyword: "",
        
        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 12
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post("/api/mypage/comment/list", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id
                }
            }).then((res) => {
                console.log(res)
                this.list = res.data
            })
        },

        // 읽기
        read(item){
            this.$http.post("/api/mypage/comment/check/board", {
                params: {
                    post_id: item.post_id
                }
            }).then((res) => {
                console.log(res)
                this.$router.push("/"+res.data[0].board+"?type=read&id="+item.post_id)
            })
        }
    }
}
</script>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#333 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>