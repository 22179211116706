<template>
    <v-sheet
        :height="$vuetify.breakpoint.mobile? '':480"
        :width="$vuetify.breakpoint.mobile? '100%':250"
        :class="!$vuetify.breakpoint.mobile? 'pa-8':'pa-2'"
        class="rounded-10"
        style="box-shadow: 0px 4px 12px #ddd;"
    >
        <p
            v-if="!$vuetify.breakpoint.mobile"
            class="text-h5 mb-4"
        >
            MY
        </p>
        <v-divider v-if="!$vuetify.breakpoint.mobile"></v-divider>
        
        <v-list
            :class="!$vuetify.breakpoint.mobile? 'mt-4':''"
            lines="one"
            :dense="$vuetify.breakpoint.mobile"
        >
            <v-list-item
                v-for="item in menu"
                :key="item.title"
                :to="item.url"
                class="custom-list-item"
                :class="!$vuetify.breakpoint.mobile? '':'py-1'"
                :style="!$vuetify.breakpoint.mobile? '':'cursor:pointer; border-bottom:1px solid #ddd;'"
                v-ripple="false"
            >
                <v-list-item-icon class="ml-0 mr-4">
                    <v-icon
                        :size="$vuetify.breakpoint.mobile? '20':''"
                        :color="item.color"
                    >
                        {{item.icon}}
                    </v-icon>
                </v-list-item-icon>
                <p class="mb-1">
                    {{item.title}}
                </p>
            </v-list-item>
        </v-list>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        menu: [
            {
                color: "grey", icon: "mdi-email-outline", title: "쪽지함", url: "/my/message"
            },
            {
                color: "grey", icon: "mdi-post-outline", title: "작성글", url: "/my/article"
            },
            {
                color: "grey", icon: "mdi-comment-text-outline", title: "작성 댓글", url: "/my/comment"
            },
            {
                color: "grey", icon: "mdi-star", title: "스크랩", url: "/my/save"
            },
            {
                color: "grey", icon: "mdi-cog-outline", title: "회원정보 수정", url: "/my/update"
            },
            {
                color: "grey", icon: "mdi-exit-to-app", title: "로그아웃", url: "/auth/logout"
            },
        ]
    })
}
</script>
<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
    opacity: 0;
}
</style>