import axios from 'axios'

const PointModule = {
    amountCheck: async (menu, activity) => {
        return await axios.post('/api/point/amount', {
            params: {
                menu: menu,
                activity: activity
            }
        }).then((res) => {
            return res
        })
    },

    Update: async (user_id, amount) => {
        return await axios.post('/api/user/update/point/increase', {
            params: {
                user_id: user_id,
                amount: amount
            }
        }).then((res) => {
            return res
        })
    },

    Record: async (user_id, menu, activity, title, amount) => {
        return await axios.post('/api/point/record', {
            params: {
                user_id: user_id,
                menu: menu,
                activity: activity,
                title: title,
                amount: amount
            }
        }).then((res) => {
            return res
        })
    }
}

export default PointModule