<template>
    <div>
        <!-- 본문 -->
        <div>
            <v-icon color="grey" size="32" class="mb-6px">mdi-post-outline</v-icon>
            <span class="ml-2 text-h6">작성글</span>
            <v-divider class="mt-2 mb-8"></v-divider>

            <v-row v-if="list.length">
                <v-col
                    v-for="item in list" :key="item.id"
                    :cols="$vuetify.breakpoint.mobile? 12:4"
                    class="px-4"
                >
                    <v-card
                        class="mb-4 rounded-5"
                        style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                        @click="read(item)"
                    >
                        <!-- 썸네일 -->
                        <v-img
                            height="160"
                            style="border-radius: 5px 5px 0 0;"
                            :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail.png')"
                        ></v-img>

                        <!-- 텍스트 -->
                        <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                            <div class="d-flex justify-space-between">
                                <v-sheet width="160">
                                    <!-- 제목 -->
                                    <div
                                        class="text-subtitle-1 font-weight-medium mb-2 pr-2"
                                        style="height:44px; width:170px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                    >
                                        {{item.title}}
                                    </div>

                                    <!-- 부제목 -->
                                    <v-sheet
                                        class="mb-6"
                                        style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                    >
                                        {{item.subtitle}}
                                    </v-sheet>
                                </v-sheet>

                                <v-sheet>
                                    <!-- 공유 -->
                                    <v-btn icon @click.stop="share_open(item)" style="margin-top:-8px; margin-right:-8px;">
                                        <v-icon size="18" :color="item.id == share_id? 'orange':'grey darken-2'">mdi-share-variant-outline</v-icon>
                                    </v-btn>

                                    <!-- 스크랩 -->
                                    <v-btn icon @click.stop="scrap(item)" style="margin-top:-8px; margin-right:-12px;">
                                        <v-icon v-if="item.is_scraped==0" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                                        <v-icon v-if="item.is_scraped!=0" size="18" color="orange">mdi-star</v-icon>
                                    </v-btn>
                                </v-sheet>
                            </div>

                            <!-- 닉네임 & 조회수 & 좋아요 & 댓글 -->
                            <v-sheet width="100%" class="d-flex align-center justify-end">
                                <!-- 닉네임 & 조회수 -->
                                <span class="text-caption">
                                    {{item.nickname}}ㆍ조회수 {{item.view_count}}
                                </span>
                                <v-spacer></v-spacer>

                                <!-- 좋아요 -->
                                <v-sheet>
                                    <v-img
                                        class="mr-2 mt-1px"
                                        width="17"
                                        src="@/assets/board/like_default.png"
                                    ></v-img>
                                </v-sheet>
                                <span class="text-body-1 mb-1px font-weight-medium">
                                    {{item.like_count}}
                                </span>

                                <!-- 댓글 -->
                                <v-sheet>
                                    <v-img
                                        class="ml-4 mr-2"
                                        width="14"
                                        src="@/assets/board/comment.png"
                                    ></v-img>
                                </v-sheet>
                                <span class="text-body-1 mb-1px font-weight-medium">
                                    {{item.comment_count}}
                                </span>
                            </v-sheet>
                        </v-sheet>
                    </v-card>
                </v-col>
            </v-row>

            <v-sheet
                v-if="!list.length"
                class="py-16 mb-8 text-center"
            >
                <v-icon size="60" color="grey lighten-1" class="mb-3">mdi-post-outline</v-icon>
                <p class="grey--text text--lighten-1">작성하신 게시글이 없습니다</p>
            </v-sheet>

            <!-- 페이징 -->
            <v-pagination
                class="mt-6"
                v-model="page"
                :length="1"
                :total-visible="7"
            ></v-pagination>
        </div>

        <!-- 게시글 공유하기 (dialog) -->
        <v-dialog
            v-model="dialog.share"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    게시글 공유하기
                </p>

                <!-- 제보항목 -->
                <v-text-field
                    style="width:300px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    placeholder="공유할 상대의 닉네임 입력"
                ></v-text-field>

                <!-- 버튼 -->
                <div class="d-flex justify-center">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.share = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="share_submit()"
                    >
                        공유하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        // 검색
        select_list: {
            search_type: ["전체", "글쓴이", "제목", "내용"]
        },
        search_type: "전체",
        keyword: "",
        
        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,

        share_id: "",
        dialog: {
            share: false
        }
    }),

    watch: {
        dialog: {
            deep:true,
            handler(newData) {
                if(!newData.share){
                    this.share_id = ""
                }
            }
        }
    },

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post("/api/mypage/article", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 게시글로 이동
        read(item){
            this.$router.push("/"+item.board + "?type=read&id=" + item.id)
        },

        // 스크랩하기
        scrap(item){
            if(item.is_scraped == 0){
                this.$http.post("/api/board/insert/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }else{
                this.$http.post("/api/board/delete/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }
        },

        // 공유하기 열기
        share_open(item){
            this.share_id = item.id
            this.dialog.share = true
        }
    }
}
</script>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#333 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>