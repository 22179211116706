<template>
    <v-sheet 
        :width="width"
        :height="height"
        style="cursor:pointer"
        @click="goToLink()"
    >
        <v-img
            :width="width"
            :height="height"
            :src="'/upload/banner/'+encodeURI(banner_image)"
        >
            <!-- <v-sheet
                :width="$vuetify.breakpoint.mobile? '100%':1200"
                class="mx-auto"
                style="padding-top:100px; padding-left:30px;"
                color="transparent"
            >
                <p class="text-h6 font-weight-bold white--text mb-4">
                    신규컨텐츠 보러가기
                    <v-icon class="mb-1" color="white">mdi-chevron-right</v-icon>
                </p>
                <p class="text-h4 font-weight-bold white--text" style="line-height:50px;">
                    퇴근길 노을을 바라보며 즐기는<br/>
                    감각적인 R&B 앨범 5
                </p>
            </v-sheet> -->
        </v-img>
    </v-sheet>
</template>
<script>
export default {
    props: ["type", "width", "height", "title", "subtitle"],

    data: () => ({
        banner_image: "",
        link: ""
    }),

    created(){
        this.$http.post('/api/admin/banner/import', {
            params: {
                type: this.type,
            }
        }).then((res) => {
            this.banner_image = res.data[0].banner_image
            this.link = res.data[0].link
        })
    },

    methods: {
        goToLink(){
            window.open(this.link, '_blank')
        }
    }
}
</script>