<template>
    <v-sheet
        width="1000"
        class="pa-4 mt-2 rounded-10 mx-auto"
        style="position:relative;"
    >
        <!-- 제목 -->
        <p class="text-h5 text-center font-weight-medium">
            TIPTAP TEST
        </p>

        <!-- 본문 -->
        <TipTapWriter 
            class="tiptap_style mt-2"
            :options="options"
            :key="componentKey"
        />
    </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        TipTapWriter
    },

    data: () => ({
        title: '',
        subtitle: '',

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0,
    })
}
</script>
<style scoped>
.table_style{
    width:100%;
    background:white;
    border:1px solid #ddd;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ddd;
    padding:4px;
    text-align: center;
}

.filled{
    background:#ddd;
    color:#999;
    font-weight:300;
}

.reservation{
    background:#bba8ca;
    color:#84679a;
    font-weight:300;
}
</style>