<template>
    <div>
        <!-- 배너 (Mobile) -->
        <BannerViewBoard
            v-if="$vuetify.breakpoint.mobile"
            type="search_mobile"
            :width="$vuetify.breakpoint.width"
            :height="$vuetify.breakpoint.width/2.7"
            title="SEARCH"
            subtitle="원하는 게시글을 찾아보아요"
        />

        <!-- 배너 (PC) -->
        <BannerViewBoard
            v-if="!$vuetify.breakpoint.mobile"
            type="search"
            width="1920"
            height="240"
            title="SEARCH"
            subtitle="원하는 게시글을 찾아보아요"
        />

        <!-- 본문 -->
        <v-sheet
            :width="$vuetify.breakpoint.mobile? '100%':1200"
            class="mx-auto pt-6 pb-16 px-5"
        >
            <v-text-field
                autofocus
                style="width:640px; box-shadow: 0px 4px 16px #dedede;"
                class="mx-auto rounded-xl"
                :class="$vuetify.breakpoint.mobile? 'mt-2 mb-10':'mt-10 mb-14'"
                :dense="$vuetify.breakpoint.mobile"
                outlined
                hide-details
                placeholder="닉네임, 제목, 부제목, 내용 또는 #해시태그"
                append-icon="mdi-magnify"
                v-model="keyword"
                @keyup.enter="search()"
            ></v-text-field>

            <v-row v-if="list.length">
                <v-col
                    v-for="item in list" :key="item.id"
                    :cols="$vuetify.breakpoint.mobile? 12:4"
                    :class="$vuetify.breakpoint.mobile? '':'px-6'"
                >
                    <v-card
                        class="mb-10 rounded-5"
                        style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                        @click="read(item)"
                    >
                        <!-- 썸네일 -->
                        <v-img
                            height="200"
                            style="border-radius: 5px 5px 0 0;"
                            :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail.png')"
                        ></v-img>

                        <!-- 텍스트 -->
                        <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                            <div class="d-flex justify-space-between">
                                <v-sheet width="240">
                                    <!-- 제목 -->
                                    <div
                                        class="text-subtitle-1 font-weight-medium mb-2 pr-2"
                                        style="white-space:pre-line; height:44px; width:240px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                    >
                                        {{item.title}}
                                    </div>

                                    <!-- 부제목 -->
                                    <v-sheet
                                        class="mb-6"
                                        style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                    >
                                        {{item.subtitle}}
                                    </v-sheet>
                                </v-sheet>

                                <v-sheet>
                                    <!-- 공유 -->
                                    <v-btn icon @click.stop="share_open(item)" style="margin-top:-8px; margin-right:-8px;">
                                        <v-icon size="18" :color="item.id == share_id? 'orange':'grey darken-2'">mdi-share-variant-outline</v-icon>
                                    </v-btn>

                                    <!-- 스크랩 -->
                                    <v-btn icon @click.stop="scrap(item)" style="margin-top:-8px; margin-right:-12px;">
                                        <v-icon v-if="item.is_scraped==0" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                                        <v-icon v-if="item.is_scraped!=0" size="18" color="orange">mdi-star</v-icon>
                                    </v-btn>
                                </v-sheet>
                            </div>

                            <!-- 닉네임 & 조회수 & 좋아요 & 댓글 -->
                            <v-sheet width="100%" class="d-flex align-center justify-end">
                                <!-- 닉네임 & 조회수 -->
                                <span class="text-caption">
                                    {{item.nickname}}ㆍ조회수 {{item.view_count}}
                                </span>
                                <v-spacer></v-spacer>

                                <!-- 좋아요 -->
                                <v-sheet>
                                    <v-img
                                        class="mr-2 mt-1px"
                                        width="17"
                                        src="@/assets/board/like_default.png"
                                    ></v-img>
                                </v-sheet>
                                <span class="text-body-1 mb-1px font-weight-medium">
                                    {{item.like_count}}
                                </span>

                                <!-- 댓글 -->
                                <v-sheet>
                                    <v-img
                                        class="ml-4 mr-2"
                                        width="14"
                                        src="@/assets/board/comment.png"
                                    ></v-img>
                                </v-sheet>
                                <span class="text-body-1 mb-1px font-weight-medium">
                                    {{item.comment_count}}
                                </span>
                            </v-sheet>
                        </v-sheet>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="!list.length">
                <v-col class="text-center py-10">
                    <v-sheet
                        class="py-16"
                    >
                        <v-icon size="60" color="grey lighten-1" class="mb-3">mdi-close-octagon-outline</v-icon>
                        <p class="grey--text text--lighten-1">검색결과가 없습니다</p>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-sheet>

        <!-- 게시글 공유하기 (dialog) -->
        <v-dialog
            v-model="dialog.share"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    게시글 공유하기
                </p>

                <!-- 제보항목 -->
                <v-text-field
                    style="width:300px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    placeholder="공유할 상대의 닉네임 입력"
                ></v-text-field>

                <!-- 버튼 -->
                <div class="d-flex justify-center">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.share = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="share_submit()"
                    >
                        공유하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import BannerViewBoard from '@/components/BannerViewBoard'

export default {
    components: {
        BannerViewBoard
    },

    data: () => ({
        keyword: "",
        list: [],

        page: 1,
        pageCount: 1,
        itemsPerPage: 12,

        share_id: "",
        dialog: {
            share: false
        }
    }),

    watch: {
        dialog: {
            deep:true,
            handler(newData) {
                if(!newData.share){
                    this.share_id = ""
                }
            }
        }
    },

    methods: {
        // 검색
        search(){
            this.$http.post("/api/search", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id,
                    keyword: this.keyword
                }
            }).then((res) => {
                this.list = res.data.slice(0,100)

                // 썸네일 추가
                this.matchThumbnail(this.list)
            })
        },

        // 썸네일 추가
        matchThumbnail(list){
            // 이미지
            const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i

            list.forEach(e => {
                if (typeof e.content === 'string' && e.content.match(imgReg)) {
                    e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1');
                }
            })
        },

        // 게시글로 이동
        read(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 읽기는 로그인 후 이용가능합니다.")
            }else{
                this.$router.push("/"+item.board + "?type=read&id=" + item.id)
            }
        },

        // 스크랩하기
        scrap(item){
            if(item.is_scraped == 0){
                this.$http.post("/api/board/insert/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.search()
                    }
                })
            }else{
                this.$http.post("/api/board/delete/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.search()
                    }
                })
            }
        },

        // 공유하기 열기
        share_open(item){
            this.share_id = item.id
            this.dialog.share = true
        }
    }
}
</script>
<style scoped>
::v-deep .v-text-field input {
    margin:0px 8px;
    font-size: 18px;
}

::v-deep .v-input__icon {
    margin:0px 8px;
}

::v-deep .v-select input {
    margin:0px 8px;
    font-size: 18px;
}
</style>