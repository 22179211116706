<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">회원 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="admin lighten-2"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    hide-default-header
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 100, -1],
                        'itemsPerPageText': '페이지당 회원 수'
                    }"
                    no-data-text=""
                >
                    <template v-slot:header>
                        <tr>
                            <th style="width:52px;"></th>
                            <th style="width:100%;">회원 등급</th>
                            <th style="width:140px;">유저 아이디</th>
                            <th style="width:180px">닉네임</th>
                            <th style="width:140px">게시글 작성 수</th>
                            <th style="width:140px">댓글 작성 수</th>
                            <th style="width:220px">이메일 주소</th>
                            <th style="width:180px">이메일 인증</th>
                            <th style="width:140px">PW 초기화</th>
                            <th style="width:220px">가입일자</th>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio
                                    class="ml-1"
                                    hide-details
                                    dense
                                    :value="item"
                                    @change="selectedItem = item"
                                ></v-radio>
                            </td>
                            <td class="px-3">
                                <v-select
                                    hide-details
                                    outlined
                                    dense
                                    :items="select_list.type"
                                    v-model="item.type"
                                    @change="updateItemType(item)"
                                ></v-select>
                            </td>
                            <td>{{item.user_id}}</td>
                            <td>{{item.nickname}}</td>
                            <td>{{item.article_count}}개</td>
                            <td>{{item.comment_count + item.sub_comment_count}}개</td>
                            <td>{{item.email}}</td>
                            <td class="px-3">
                                <v-select
                                    hide-details
                                    outlined
                                    dense
                                    :items="select_list.status"
                                    v-model="item.status"
                                    @change="update_status(item)"
                                ></v-select>
                            </td>
                            <td>
                                <v-btn
                                    depressed
                                    block
                                    class="text-caption"
                                    style="border:1px solid #ccc;"
                                    @click="passwordReset(item)"
                                >
                                    PW 초기화
                                </v-btn>
                            </td>
                            <td>{{new Date(item.created).toLocaleString()}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>
            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4 normalize"
                    disabled
                    large
                >
                    선택항목: {{selectedItem.user_id}}
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="admin lighten-2"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    회원 삭제하기
                </v-btn>
            </v-card>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '유저 아이디', value: 'user_id'},
            {text: '닉네임', value: 'nickname'},
            {text: '게시글 작성 수', value: 'article_count'},
            {text: '댓글 작성 수', value: 'point'},
            {text: '포인트', value: 'point'},
            {text: '이메일 주소', value: 'email'},
            {text: '이메일 인증', value: 'status'},
            {text: '가입일자', value: 'created'}
        ],

        list: [],
        selected: false,
        selectedItem: {},

        // 선택지
        select_list: {
            // 검색 타입
            search_type: [
                "유저 아이디", 
                "닉네임", 
                "이메일 주소"
            ],

            // 이메일 인증
            status: ["미인증", "인증완료"],

            // 회원 등급
            type: [
                "일반",
                "인플루현서",
                "문화 테이스터",
                "프로디깅러",
                "프로커뮤니케이터",
                "관리자"
            ]
        },

        // 검색 타입
        search_type: "유저 아이디",

        // 검색 키워드
        keyword: ""
    }),

    mounted(){
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/user/select/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
        },

        // 회원등급 변경하기
        updateItemType(item){
            this.$http.post('/api/admin/user/update/type', {
                params: {
                    type: item.type,
                    user_id: item.user_id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("해당 회원의 회원등급이 변경되었습니다.")
                }
            })
        },

        // 이메일 인증여부 변경하기
        update_status(item){
            this.$http.post('/api/admin/user/update/status', {
                params: {
                    status: item.status,
                    user_id: item.user_id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("이메일 인증 상태가 변경되었습니다.")
                }
            })
        },

        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 회원이 없습니다")
            }else{
                if(confirm("정말 해당 회원을 삭제하겠습니까?")){
                    this.$http.post('/api/admin/user/delete', {
                        params: {
                            id: this.selectedItem.id
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            alert("해당 회원이 정상적으로 삭제되었습니다.")
                            this.search()
                        }
                    })
                }
            }
        },

        // 패스워드 초기화
        passwordReset(item){
            this.$http.post('/api/admin/user/update/password/reset', {
                params: {
                    user_id: item.user_id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("해당 회원의 비밀번호가 초기화되었습니다.")
                }
            })
        }
    }
}
</script>
<style scoped>
/* 버튼 대소문자 고정 해제 */
.v-btn {
    text-transform:none !important;
}
</style>