<template>
    <div>
        <Header
            v-if="!$route.path.startsWith('/tests')"
        />
        <router-view :key="$route.fullPath"></router-view>
        <Footer
            v-if="!$route.path.startsWith('/tests')"
        />
    </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
    components: {
        Header,
        Footer
    }
}
</script>