<template>
    <div class="pb-16">
        <!-- 배너 (Mobile) -->
        <BannerViewBoard
            v-if="$vuetify.breakpoint.mobile"
            type="my_mobile"
            :width="$vuetify.breakpoint.width"
            :height="$vuetify.breakpoint.width/2.7"
            title="MY"
            subtitle="나만의 저장소"
        />
        
        <!-- 배너 (PC) -->
        <BannerViewBoard
            v-if="!$vuetify.breakpoint.mobile"
            type="my"
            width="1920"
            height="240"
            title="MY"
            subtitle="나만의 저장소"
        />
    
        <!-- 본문 -->
        <v-sheet
            :width="$vuetify.breakpoint.mobile? '100%':1200"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 mx-auto d-flex justify-space-between':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 내용 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 920:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-6 pt-8':'pa-4 py-4'"
                class="rounded-10"
            >
                <router-view :key="$route.fullPath"></router-view>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import BannerViewBoard from '@/components/BannerViewBoard'
import SideMenu from './my/components/SideMenu'

export default {
    components: {
        BannerViewBoard,
        SideMenu
    }
}
</script>