<template>
    <div>
        <!-- Mobile -->
        <Mobile
            v-if="$vuetify.breakpoint.mobile"
        />

        <!-- Pc -->
        <Pc
            v-if="!$vuetify.breakpoint.mobile"
        />
    </div>
</template>
<script>
import Mobile from './HomeList/Mobile'
import Pc from './HomeList/Pc'

export default {
    components: {
        Mobile,
        Pc
    }
}
</script>