<template>
    <v-sheet
        style="border-bottom:1px solid #eee;"
    >
        <v-sheet
            height="64"
            class="px-3 d-flex justify-space-between align-center"
        >
            <!-- 사이드메뉴 -->
            <v-btn
                icon
                @click="drawer = !drawer"
            >
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            <!-- 로고 -->
            <v-sheet 
                width="80"
                style="cursor:pointer;"
                @click="link('/')"
            >
                <v-img
                    contain
                    src="@/assets/logo/horizon_default.svg"
                ></v-img>
            </v-sheet>

            <!-- 계정 -->
            <div>
                <v-icon
                    v-if="!$store.state.hyundai_user.is_logined"
                    size="28"
                    style="cursor:pointer;"
                    @click="link('/auth/login')"
                >
                    mdi-account-circle-outline
                </v-icon>

                <v-img
                    v-if="$store.state.hyundai_user.is_logined"
                    class="rounded-circle"
                    style="width:28px; height:28px;"
                    :src="'/upload/user/profile_image/' + $store.state.hyundai_user.profile_image"
                    @click="link('/my/message')"
                ></v-img>
            </div>
        </v-sheet>

        <!-- 사이드메뉴 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
            <v-list
                nav
                class="font-weight-medium px-2"
            >
                <v-list-item
                    v-for="(item, index) in menu" :key="index"
                    :to="item.url"
                    class="px-4"
                    active-class="grey--lighten-2"
                >
                    {{item.title}}
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        drawer: false,

        menu: [
            {
                    title: "홈",
                    url: "/"
            },
            {
                    title: "GENRE",
                    url: "/genre?type=list"
            },
            {
                    title: "MOOD",
                    url: "/mood?type=list"
            },
            {
                    title: "커뮤니티", 
                    url: "/community?type=list"
            },
            {
                    title: "MY",
                    url: "/my/message"
            },
            {
                    title: "SEARCH",
                    url: "/search"
            }
        ]
    }),

    methods: {
        link(url){
            this.$router.push(url)
        }
    }
}
</script>