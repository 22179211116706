<template>
    <v-sheet
        :width="$vuetify.breakpoint.mobile? '100%':1200"
        class="mx-auto pt-6 pb-16 px-5"
    >
        <!-- 상단 -->
        <div
            class="d-flex mr-3"
        >
            <!-- 카테고리 -->
            <v-tabs
                class="px-2 mb-6 rounded-circle "
                style="width:100%;"
                v-model="tabs"
                hide-slider
            >
                <v-tab
                    style="letter-spacing:-0.5px; font-size:16px; background:none !important;"
                    class="font-weight-bold px-1 mr-1"
                    content-class="rounded-15"
                    v-for="item in category_list" :key="'category'+item"
                    @change="categoryUpdated(item)"
                    v-ripple="false"
                >
                    <v-sheet
                        outlined
                        min-width="100"
                        class="rounded-15 pt-6px pb-2 px-5 mx-0 text-subtitle-1 font-weight-medium"
                        :style="selectedCategory == item? 'border:1px solid #777;':''"
                        :class="selectedCategory == item? 'black--text font-weight-bold':'grey--text'"
                    >
                        {{ item }}
                    </v-sheet>
                </v-tab>
            </v-tabs>

            <v-spacer></v-spacer>
            <v-btn
                v-if="canWrite"
                class="mt-1"
                outlined
                style="border:1px solid #e0e0e0;"
                fab
                small
                height="44"
                width="44"
                @click="write()"
            >
                <v-icon color="#ccc">mdi-pencil-outline</v-icon>
            </v-btn>
        </div>

        <v-row v-if="list.length">
            <v-col
                v-for="item in list" :key="item.id"
                :cols="$vuetify.breakpoint.mobile? 12:4"
                :class="$vuetify.breakpoint.mobile? '':'px-6'"
            >
                <v-card
                    class="mb-10 rounded-5"
                    style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                    @click="read(item)"
                >
                    <!-- 썸네일 -->
                    <v-img
                        height="200"
                        style="border-radius: 5px 5px 0 0;"
                        :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail.png')"
                    ></v-img>

                    <!-- 텍스트 -->
                    <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                        <div
                            class="d-flex justify-space-between"
                            style="position:relative;"
                        >
                            <v-sheet width="240">
                                <!-- 제목 -->
                                <div
                                    class="text-subtitle-1 font-weight-medium mb-2 pr-2"
                                    style="white-space:pre-line; height:44px; width:240px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                >
                                    {{item.title}}
                                </div>

                                <!-- 부제목 -->
                                <v-sheet
                                    class="mb-6"
                                    style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                >
                                    {{item.subtitle}}
                                </v-sheet>
                            </v-sheet>

                            <v-sheet>
                                <!-- 공유하기 -->
                                <v-btn icon @click.stop="share_open(item)" style="margin-top:-8px; margin-right:-8px;">
                                    <v-icon size="18" :color="item.id == share_id? 'orange':'grey darken-2'">mdi-share-variant-outline</v-icon>
                                </v-btn>

                                <!-- 스크랩 -->
                                <v-btn icon @click.stop="scrap(item)" style="margin-top:-8px; margin-right:-12px;">
                                    <v-icon v-if="item.is_scraped==0" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                                    <v-icon v-if="item.is_scraped!=0" size="18" color="orange">mdi-star</v-icon>
                                </v-btn>
                            </v-sheet>

                            <!-- 스크랩 -->
                            <v-btn icon @click.stop="toTop(item)" style="position:absolute; top:20px; right:-12px;">
                                <v-icon v-if="item.is_top==0" size="18" color="grey darken-2">mdi-check-circle-outline</v-icon>
                                <v-icon v-if="item.is_top!=0" size="18" color="orange">mdi-check-circle</v-icon>
                            </v-btn>
                        </div>

                        <!-- 닉네임 & 조회수 & 좋아요 & 댓글 -->
                        <v-sheet width="100%" class="d-flex align-center justify-end">
                            <!-- 닉네임 & 조회수 -->
                            <span class="text-caption">
                                {{item.nickname}}ㆍ조회수 {{item.view_count}}
                            </span>
                            <v-spacer></v-spacer>

                            <!-- 좋아요 -->
                            <v-sheet>
                                <v-img
                                    class="mr-2 mt-1px"
                                    width="17"
                                    src="@/assets/board/like_default.png"
                                ></v-img>
                            </v-sheet>
                            <span class="text-body-1 mb-1px font-weight-medium">
                                {{item.like_count}}
                            </span>

                            <!-- 댓글 -->
                            <v-sheet>
                                <v-img
                                    class="ml-4 mr-2"
                                    width="14"
                                    src="@/assets/board/comment.png"
                                ></v-img>
                            </v-sheet>
                            <span class="text-body-1 mb-1px font-weight-medium">
                                {{item.comment_count + item.sub_comment_count}}
                            </span>
                        </v-sheet>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!list.length">
            <v-col class="text-center py-10">
                <v-sheet
                    class="py-16"
                >
                    <v-icon size="60" color="grey lighten-1" class="mb-3">mdi-close-octagon-outline</v-icon>
                    <p class="grey--text text--lighten-1">게시글이 없습니다</p>
                </v-sheet>
            </v-col>
        </v-row>

        <v-sheet class="d-flex align-center px-3 mt-2">
            <v-sheet
                width="140"
            ></v-sheet>
            <v-spacer></v-spacer>
            <!-- 페이징 -->
            <v-pagination
                v-model="page"
                color="grey"
                :length="pageCount"
                :total-visible="7"
            ></v-pagination>
            <v-spacer></v-spacer>
            <v-btn
                v-if="canWrite"
                width="140"
                class="rounded-5"
                style="box-shadow: 0px 4px 12px #ddd;"
                color="grey"
                depressed
                dark
                @click="write()"
            >
                작성하기
            </v-btn>
        </v-sheet>

        <!-- 게시글 공유하기 (dialog) -->
        <v-dialog
            v-model="dialog.share"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    게시글 공유하기
                </p>

                <!-- 제보항목 -->
                <v-text-field
                    style="width:300px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    placeholder="공유할 상대의 닉네임 입력"
                    v-model="share_nickname"
                ></v-text-field>

                <!-- 버튼 -->
                <div class="d-flex justify-center">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.share = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="share_submit()"
                    >
                        공유하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        tabs: null,
        category_list: [],
        selectedCategory: "모아보기",
        list: [],

        page: 1,
        pageCount: 1,
        itemsPerPage: 12,

        share_id: "",
        share_nickname: "",
        dialog: {
            share: false
        },

        canWrite: false,

        user: {}
    }),

    watch: {
        dialog: {
            deep:true,
            handler(newData) {
                if(!newData.share){
                    this.share_id = ""
                }
            }
        }
    },

    mounted(){
        // 카테고리 불러오기
        this.loadCategory()

        // 전체 게시글 불러오기
        this.loadArticle()

        // 유저 정보 불러오기
        this.load()
    },

    methods: {
        // 유저정보 불러오기
        load(){
            this.$http.post("/api/mypage/update/load", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id
                }
            }).then((res) => {
                this.user = res.data[0]
                if(this.$route.path.startsWith("/genre") || this.$route.path.startsWith("/mood")){
                    if(this.user.type == '관리자'){
                        this.canWrite = true
                    }
                }else if(this.$route.path.startsWith("/community")){
                    // if(
                    //     this.user.type == '관리자'
                    //     || this.user.type == '인플루현서'
                    //     || this.user.type == '문화 테이스터'
                    // ){
                    //     this.canWrite = true
                    // }else if(
                    //         (this.user.type == '일반'
                    //         || this.user.type == '프로디깅러'
                    //         || this.user.type == '프로커뮤니케이터')
                    //     &&
                    //         this.selectedCategory == "ETC"
                    // ){
                    //     this.canWrite = true
                    // }
                    this.canWrite = true
                }
            })
        },

        // 카테고리 불러오기
        loadCategory(){
            this.$http.post("/api/board/category/select/specific", {
                params: {
                    board: this.$route.path.slice(1)
                }
            }).then((res) => {
                this.category_list = res.data.map(e => e.category)
                this.category_list.unshift("모아보기")
            })
        },

        // 카테고리 선택
        categoryUpdated(item){
            this.selectedCategory = item
            this.loadArticle()
        },

        // 게시글 목록 불러오기
        loadArticle(){
            this.$http.post("/api/board/select/specific/list", {
                params: {
                    board: this.$route.path.slice(1),
                    user_id: this.$store.state.hyundai_user.user_id,
                    category: this.selectedCategory
                }
            }).then((res) => {
                this.list = res.data

                // 썸네일 추가
                this.matchThumbnail(this.list)
            })
        },

        // 썸네일 추가
        matchThumbnail(list) {
            // <img> 태그용 정규식
            const imgReg = /<img[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
            // CustomImage 태그용 정규식 (대소문자 구분 없음)
            const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;

            list.forEach(e => {
                if (typeof e.content === 'string') {
                    const imgMatch = e.content.match(imgReg);
                    const customImgMatch = e.content.match(customImgReg);

                    if (customImgMatch) {
                        // CustomImage 태그에서 src 추출
                        e.image = customImgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if (imgMatch) {
                        // <img> 태그에서 src 추출
                        e.image = imgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                }
            });
        },

        // 읽기로 이동
        read(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 읽기는 로그인 후 이용가능합니다.")
            }else{
                this.$router.push(this.$route.path + "?type=read&id=" + item.id)
            }
        },

        // 작성하기
        write(){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 읽기는 로그인 후 이용가능합니다.")
            }else{
                this.$router.push(this.$route.path+'?type=write')
            }
        },

        // 스크랩하기
        scrap(item){
            if(item.is_scraped == 0){
                this.$http.post("/api/board/insert/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.loadArticle()
                    }
                })
            }else{
                this.$http.post("/api/board/delete/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.loadArticle()
                    }
                })
            }
        },

        // 공유하기 열기
        share_open(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 공유는 로그인 후 이용가능합니다.")
            }else{
                this.share_id = item.id
                this.dialog.share = true
            }
        },

        // 공유하기 제출
        share_submit(){
            if(this.share_nickname == ""){
                alert("공유할 대상의 닉네임을 입력해주세요")
            }else{
                this.$http.post("/api/board/share/checkNickname", {
                    params: {
                        nickname: this.share_nickname
                    }
                }).then((res) => {
                    if(!res.data.length){
                        alert("입력하신 닉네임에 해당하는 사용자를 찾지 못하였습니다.\n닉네임을 다시 확인해주세요.")
                    }else if(res.data[0].user_id == this.$store.state.hyundai_user.user_id){
                        alert("자신에게 게시글 공유하기를 할 수 없습니다.")
                    }else{
                        this.$http.post("/api/board/share/message/insert", {
                            params: {
                                sender: this.$store.state.hyundai_user.user_id,
                                receiver: res.data[0].user_id,
                                post_id: this.share_id
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                alert("해당 게시글이 상대에게 공유되었습니다.")
                                this.dialog.share = false
                            }
                        })
                    }
                })
            }
        },

        toTop(item){
            if(this.$store.state.hyundai_admin.is_logined){
                this.$http.post("/api/board/update/is_top", {
                    params: {
                        post_id: item.id,
                        is_top: item.is_top==0? 1:0 
                    }
                }).then((res) => {
                    console.log(res)
                    if(res.data.affectedRows){
                        this.loadArticle()
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>

<style>
.v-tab--active {
    background-color: white;
}
.v-pagination__item {
    color: #333 !important;
}

.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before {
    opacity: 0 !important
}
.theme--light.v-tabs .v-tab:hover::before {
    opacity: 0 !important
}
.v-tab:before {
    color:white;
}
</style>