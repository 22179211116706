<template>
    <div>
        <v-sheet
            height="90"
            :width="$vuetify.breakpoint.mobile? '100%':1200"
            class="d-flex align-center mx-auto"
        >
            <!-- 로고 -->
            <v-sheet 
                width="280"
                class="d-flex justify-center mt-4 mr-10"
            >
                <v-img
                    height="54"
                    contain
                    style="cursor:pointer;"
                    src="@/assets/logo/horizon_default.svg"
                    @click="link('/')"
                ></v-img>
            </v-sheet>

            <v-spacer></v-spacer>

            <!-- 메뉴 -->
            <div>
                <v-btn
                    v-ripple="false"
                    class="px-3 text-subtitle-1 grey--text text--darken-1 font-weight-bold mr-6 rounded-0"
                    style="border-bottom:2px solid #ffffff;"
                    text
                    large
                    active-class="grey--text text--darken-4"
                    exact
                    to="/"
                >
                    홈
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="px-3 text-subtitle-1 grey--text text--darken-1 font-weight-bold mr-6 rounded-0"
                    style="border-bottom:2px solid #ffffff;"
                    text
                    large
                    active-class="grey--text text--darken-4"
                    to="/genre?type=list"
                >
                    GENRE
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="px-4 mr-5 text-subtitle-1 grey--text text--darken-1 font-weight-bold rounded-0"
                    style="border-bottom:2px solid #ffffff;"
                    text
                    large
                    active-class="grey--text text--darken-4"
                    to="/mood?type=list"
                >
                    MOOD
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="px-4 mr-6 text-subtitle-1 grey--text text--darken-1 font-weight-bold rounded-0"
                    style="border-bottom:2px solid #ffffff;"
                    text
                    large
                    active-class="grey--text text--darken-4"
                    to="/community?type=list"
                >
                    커뮤니티
                </v-btn>
                <v-btn
                    v-if="!$store.state.hyundai_user.is_logined"
                    v-ripple="false"
                    class="px-6 mr-2 text-subtitle-1 grey--text text--darken-1 font-weight-bold rounded-0"
                    style="border-bottom:2px solid #ffffff;"
                    text
                    large
                    active-class="grey--text text--darken-4"
                    exact
                    to="/auth/login"
                >
                    LOGIN
                </v-btn>
                <v-btn
                    v-if="$store.state.hyundai_user.is_logined"
                    v-ripple="false"
                    class="px-6 mr-2 text-subtitle-1 grey--text text--darken-1 font-weight-bold rounded-0"
                    style="border-bottom:2px solid #ffffff;"
                    text
                    large
                    active-class="grey--text text--darken-4"
                    exact
                    to="/my/message"
                >
                    <span :class="$route.path.startsWith('/my')? 'grey--text text--darken-4':''">MY</span>
                </v-btn>
                <v-btn
                    v-ripple="false"
                    text
                    large
                    class="grey--text text--darken-1"
                    style="border:none !important;"
                    active-class="grey--text text--darken-4"
                    to="/search"
                >
                    <v-icon class="pt-2px">
                        mdi-magnify
                    </v-icon>
                </v-btn>
            </div>
        </v-sheet>
    </div>
</template>
<script>
export default {
    methods: {
        link(url){
            this.$router.push(url)
        }
    }
}
</script>

<style scoped>
.v-btn::before {
    background-color: transparent;
}

.v-btn:hover {
    color: #000000;
    border-bottom:2px solid #000 !important;
}
</style>