<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">홈 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-4 pb-16">
            <v-sheet
                :width="$vuetify.breakpoint.mobile? '100%':1200"
                class="pt-6 pb-16 px-5"
            >
                <v-row>
                    <v-col cols="6" class="px-6">
                        <!-- 인기 콘텐츠 제목 -->
                        <div class="d-flex justify-space-between">
                            <v-sheet>
                                <p class="text-h6 mb-1">
                                    인기 콘텐츠 TOP3
                                </p>
                                <p class="text-subtitle-2 font-weight-regular grey--text text--darken-1">
                                    지금 가장 인기있는 콘텐츠를 읽어보세요.
                                </p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-btn
                                outlined
                                color="#313942"
                                @click="dialog.select = true"
                            >
                                게시글 선택
                            </v-btn>
                        </div>

                        <!-- 인기 콘텐츠 목록 -->
                        <v-card
                            v-for="item in hot_list" :key="item.id"
                            class="mb-4 d-flex rounded-5"
                            style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                            @click="read(item)"
                        >
                            <!-- 썸네일 -->
                            <v-img
                                width="210"
                                height="150"
                                style="border-radius: 5px 0 0 5px;"
                                :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail_main.png')"
                            ></v-img>

                            <!-- 텍스트 -->
                            <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                                <div class="d-flex justify-space-between mb-3">
                                    <v-sheet width="230">
                                        <!-- 제목 -->
                                        <div
                                            class="text-subtitle-1 font-weight-medium mb-1 pr-2"
                                            style="white-space:pre-line; height:44px; width:240px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                        >
                                            {{item.title}}
                                        </div>

                                        <!-- 부제목 -->
                                        <v-sheet
                                            class="mb-1"
                                            style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                        >
                                            {{item.subtitle}}
                                        </v-sheet>
                                    </v-sheet>

                                    <v-sheet>
                                        <!-- 공유 -->
                                        <v-btn icon style="margin-top:-8px; margin-right:-8px;">
                                            <v-icon size="18" color="grey darken-2">mdi-share-variant-outline</v-icon>
                                        </v-btn>

                                        <!-- 스크랩 -->
                                        <v-btn icon style="margin-top:-8px; margin-right:-12px;">
                                            <v-icon v-if="item.is_scraped==0" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                                            <v-icon v-if="item.is_scraped!=0" size="18" color="orange">mdi-star</v-icon>
                                        </v-btn>
                                    </v-sheet>
                                </div>

                                <!-- 닉네임 & 조회수 -->
                                <v-sheet class="d-flex align-center text-caption">
                                    {{item.nickname}}ㆍ조회수 {{item.view_count}}
                                </v-sheet>
                            </v-sheet>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="px-6">
                        <!-- 커뮤니티 인기글 제목 -->
                        <div class="d-flex justify-space-between">
                            <v-sheet>
                                <p class="text-h6 mb-1">
                                    커뮤니티 인기글
                                </p>
                                <p class="text-subtitle-2 font-weight-regular grey--text text--darken-1">
                                    지금 가장 인기있는 글을 읽어보세요.
                                </p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-btn
                                outlined
                                color="#313942"
                                @click="dialog.select2 = true"
                            >
                                게시글 선택
                            </v-btn>
                        </div>

                        <!-- 커뮤니티 인기글 목록 -->
                        <v-card
                            v-for="item in hot_community_list" :key="item.id"
                            class="mb-4 d-flex rounded-5"
                            style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                            @click="read(item)"
                        >
                            <!-- 썸네일 -->
                            <v-img
                                width="210"
                                height="150"
                                style="border-radius: 5px 0 0 5px;"
                                :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail_main.png')"
                            ></v-img>

                            <!-- 텍스트 -->
                            <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                                <div class="d-flex justify-space-between mb-3">
                                    <v-sheet width="230">
                                        <!-- 제목 -->
                                        <div
                                            class="text-subtitle-1 font-weight-medium mb-1 pr-2"
                                            style="white-space:pre-line; height:44px; width:240px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                        >
                                            {{item.title}}
                                        </div>

                                        <!-- 부제목 -->
                                        <v-sheet
                                            class="mb-1"
                                            style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                        >
                                            {{item.subtitle}}
                                        </v-sheet>
                                    </v-sheet>

                                    <v-sheet>
                                        <!-- 공유 -->
                                        <v-btn icon style="margin-top:-8px; margin-right:-8px;">
                                            <v-icon size="18" color="grey darken-2">mdi-share-variant-outline</v-icon>
                                        </v-btn>

                                        <!-- 스크랩 -->
                                        <v-btn icon style="margin-top:-8px; margin-right:-12px;">
                                            <v-icon v-if="item.is_scraped==0" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                                            <v-icon v-if="item.is_scraped!=0" size="18" color="orange">mdi-star</v-icon>
                                        </v-btn>
                                    </v-sheet>
                                </div>

                                <!-- 닉네임 & 조회수 -->
                                <v-sheet class="d-flex align-center text-caption">
                                    {{item.nickname}}ㆍ조회수 {{item.view_count}}
                                </v-sheet>
                            </v-sheet>
                        </v-card>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-sheet>

        <!-- 인기콘텐츠 선택하기 (dialog) -->
        <v-dialog
            v-model="dialog.select"
            content-class="rounded-xl"
            width="540"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    인기콘텐츠 선택하기
                </p>

                <!-- 첫번째 게시글 링크 -->
                <v-text-field
                    style="width:420px;"
                    class="rounded-lg mx-auto mb-3"
                    outlined
                    dense
                    hide-details
                    placeholder="첫번째 게시글 링크"
                    v-model="first_article"
                ></v-text-field>

                <!-- 두번째 게시글 링크 -->
                <v-text-field
                    style="width:420px;"
                    class="rounded-lg mx-auto mb-3"
                    outlined
                    dense
                    hide-details
                    placeholder="두번째 게시글 링크"
                    v-model="second_article"
                ></v-text-field>

                <!-- 세번째 게시글 링크 -->
                <v-text-field
                    style="width:420px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    hide-details
                    placeholder="세번째 게시글 링크"
                    v-model="third_article"
                ></v-text-field>

                <!-- 버튼 -->
                <div class="d-flex justify-center mt-6">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.select = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="updateHotContents()"
                    >
                        선택하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 커뮤니티 인기글 선택하기 (dialog) -->
        <v-dialog
            v-model="dialog.select2"
            content-class="rounded-xl"
            width="540"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    커뮤니티 인기글 선택하기
                </p>

                <!-- 첫번째 게시글 링크 -->
                <v-text-field
                    style="width:420px;"
                    class="rounded-lg mx-auto mb-3"
                    outlined
                    dense
                    hide-details
                    placeholder="첫번째 게시글 링크"
                    v-model="first_article2"
                ></v-text-field>

                <!-- 두번째 게시글 링크 -->
                <v-text-field
                    style="width:420px;"
                    class="rounded-lg mx-auto mb-3"
                    outlined
                    dense
                    hide-details
                    placeholder="두번째 게시글 링크"
                    v-model="second_article2"
                ></v-text-field>

                <!-- 세번째 게시글 링크 -->
                <v-text-field
                    style="width:420px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    hide-details
                    placeholder="세번째 게시글 링크"
                    v-model="third_article2"
                ></v-text-field>

                <!-- 버튼 -->
                <div class="d-flex justify-center mt-6">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.select2 = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="updateHotCommunity()"
                    >
                        선택하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        first_article: "",
        second_article: "",
        third_article: "",

        first_article2: "",
        second_article2: "",
        third_article2: "",

        dialog: {
            select: false,
            select2: false,
        },
        
        hot_list: [],
        hot_community_list: [],
    }),
    
    mounted(){
        // 인기 콘텐츠 불러오기
        this.loadHotContents()

        // 커뮤니티 인기글 불러오기
        this.loadHotCommunity()
    },

    methods: {
        // 인기 콘텐츠 불러오기
        loadHotContents(){
            this.$http.post("/api/admin/home/hot_contents/select")
            .then((res) => {
                console.log(res)
                this.first_article = res.data[0].first_article
                this.second_article = res.data[0].second_article
                this.third_article = res.data[0].third_article

                console.log(this.extractId(this.first_article))

                this.$http.post("/api/admin/home/hot_contents/specific", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        id: this.extractId(this.first_article)
                    }
                }).then((res) => {
                    this.hot_list = [...this.hot_list, ...res.data]
                    this.$http.post("/api/admin/home/hot_contents/specific", {
                        params: {
                            user_id: this.$store.state.hyundai_user.user_id,
                            id: this.extractId(this.second_article)
                        }
                    }).then((res) => {
                        this.hot_list = [...this.hot_list, ...res.data]
                        this.$http.post("/api/admin/home/hot_contents/specific", {
                            params: {
                                user_id: this.$store.state.hyundai_user.user_id,
                                id: this.extractId(this.third_article)
                            }
                        }).then((res) => {
                            this.hot_list = [...this.hot_list, ...res.data]

                            // 썸네일 추가
                            this.matchThumbnail(this.hot_list)
                        })
                    })
                })
            })
        },

        // 커뮤니티 인기글 불러오기
        loadHotCommunity(){
            this.$http.post("/api/admin/home/hot_community/select")
            .then((res) => {
                console.log(res)
                this.first_article2 = res.data[0].first_article
                this.second_article2 = res.data[0].second_article
                this.third_article2 = res.data[0].third_article

                console.log(this.extractId(this.first_article2))

                this.$http.post("/api/admin/home/hot_community/specific", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        id: this.extractId(this.first_article2)
                    }
                }).then((res) => {
                    this.hot_community_list = [...this.hot_community_list, ...res.data]
                    this.$http.post("/api/admin/home/hot_community/specific", {
                        params: {
                            user_id: this.$store.state.hyundai_user.user_id,
                            id: this.extractId(this.second_article2)
                        }
                    }).then((res) => {
                        this.hot_community_list = [...this.hot_community_list, ...res.data]
                        this.$http.post("/api/admin/home/hot_community/specific", {
                            params: {
                                user_id: this.$store.state.hyundai_user.user_id,
                                id: this.extractId(this.third_article2)
                            }
                        }).then((res) => {
                            this.hot_community_list = [...this.hot_community_list, ...res.data]

                            // 썸네일 추가
                            this.matchThumbnail(this.hot_community_list)
                        })
                    })
                })
            })
        },

        // 인기 콘텐츠 선택하기
        updateHotContents(){
            this.$http.post("/api/admin/home/hot_contents/update", {
                params: {
                    first_article: this.first_article,
                    second_article: this.second_article,
                    third_article: this.third_article
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("인기콘텐츠가 업데이트되었습니다.")
                    this.hot_list = []
                    this.loadHotContents()
                    this.dialog.select = false
                }
            })
        },

        // 인기 콘텐츠 선택하기
        updateHotCommunity(){
            this.$http.post("/api/admin/home/hot_community/update", {
                params: {
                    first_article: this.first_article2,
                    second_article: this.second_article2,
                    third_article: this.third_article2
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("커뮤니티 인기글이 업데이트되었습니다.")
                    this.hot_community_list = []
                    this.loadHotCommunity()
                    this.dialog.select2 = false
                }
            })
        },

        // id값 추출
        extractId(url) {
            const regex = /id=(\d+)/
            const matches = url.match(regex)

            if (matches && matches.length > 1) {
                return matches[1]
            } else {
                return null // 혹은 적절한 에러 처리
            }
        },

        // 썸네일 추가
        matchThumbnail(list) {
            // <img> 태그용 정규식
            const imgReg = /<img[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
            // CustomImage 태그용 정규식 (대소문자 구분 없음)
            const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;

            list.forEach(e => {
                if (typeof e.content === 'string') {
                    const imgMatch = e.content.match(imgReg);
                    const customImgMatch = e.content.match(customImgReg);

                    if (customImgMatch) {
                        // CustomImage 태그에서 src 추출
                        e.image = customImgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if (imgMatch) {
                        // <img> 태그에서 src 추출
                        e.image = imgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                }
            });
        },
    }
}
</script>