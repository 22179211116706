<template>
    <div>
        <!-- 제목 -->
        <v-icon color="grey" size="32" class="mb-6px">mdi-cog-outline</v-icon>
        <span class="ml-2 text-h6">회원정보 수정</span>
        <v-divider class="mt-2 mb-10"></v-divider>

        <!-- 프로필 이미지 & 이미지 수정 -->
        <v-sheet class="d-flex justify-center mb-2">
            <!-- 뱃지 -->
            <v-badge
                color="primary"
                offset-x="20"
                offset-y="20"
            >
                <!-- 프로필 이미지 -->
                <v-img
                    class="rounded-circle"
                    style="width:80px; height:80px; cursor:pointer;"
                    :src="'/upload/user/profile_image/' + user.profile_image"
                    @click="dialog.updateImage = true"
                ></v-img>

                <!-- 뱃지 아이콘 -->
                <template v-slot:badge>
                    <v-icon>mdi-cog</v-icon>
                </template>
            </v-badge>

            <!-- 프로필 이미지 수정 -->
            <v-dialog
                v-model="dialog.updateImage"
                width="auto"
            >
                <ProfileImage
                    @imageUpdated="load(); dialog.updateImage = false"
                />
            </v-dialog>
        </v-sheet>
        
        <!-- 입력란 -->
        <v-sheet
            :class="$vuetify.breakpoint.mobile? 'pa-2':'pa-8'"
        >
            <table
                class="custom_table mx-auto"
                :class="$vuetify.breakpoint.mobile? '':'pl-12'"
            >
                <tr>
                    <td class="pb-7">회원등급</td>
                    <td>
                        <v-text-field
                            :class="$vuetify.breakpoint.mobile? 'mx-2 mb-8 ':'mx-14 mb-8'"
                            class="rounded-10"
                            style="background:#f5f5f5;"
                            outlined
                            readonly
                            hide-details
                            :placeholder="user.type"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">아이디</td>
                    <td>
                        <v-text-field
                            :class="$vuetify.breakpoint.mobile? 'mx-2 mb-8 ':'mx-14 mb-8'"
                            class="rounded-10"
                            style="background:#f5f5f5;"
                            outlined
                            readonly
                            hide-details
                            :placeholder="$store.state.hyundai_user.user_id"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">현재 비밀번호<font class="ml-1 red--text">*</font></td>
                    <td>
                        <v-text-field
                            :class="$vuetify.breakpoint.mobile? 'mx-2':'mx-14'"
                            class="rounded-10"
                            outlined
                            persistent-placeholder
                            type="password"
                            v-model="password_prev"
                            @change="$v.password_prev.$touch()"
                            @blur="$v.password_prev.$touch()"
                            :error-messages="password_prev_Errors"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">새 비밀번호</td>
                    <td>
                        <v-text-field
                            :class="$vuetify.breakpoint.mobile? 'mx-2':'mx-14'"
                            class="rounded-10"
                            outlined
                            persistent-placeholder
                            type="password"
                            v-model="password_next"
                            @change="$v.password_next.$touch()"
                            @blur="$v.password_next.$touch()"
                            :error-messages="password_next_Errors"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">새 비밀번호 확인</td>
                    <td>
                        <v-text-field
                            :class="$vuetify.breakpoint.mobile? 'mx-2':'mx-14'"
                            class="rounded-10"
                            outlined
                            persistent-placeholder
                            type="password"
                            v-model="password_check"
                            @change="$v.password_check.$touch()"
                            @blur="$v.password_check.$touch()"
                            :error-messages="password_check_Errors"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td class="pb-7">닉네임</td>
                    <td>
                        <v-text-field
                            :class="$vuetify.breakpoint.mobile? 'mx-2':'mx-14'"
                            class="rounded-10"
                            outlined
                            v-model="nickname"
                            @change="$v.nickname.$touch()"
                            @blur="$v.nickname.$touch()"
                            :error-messages="nickname_Errors"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td>이메일</td>
                    <td>
                        <v-text-field
                            :class="$vuetify.breakpoint.mobile? 'mx-2':'mx-14'"
                            class="rounded-10"
                            style="background:#f5f5f5;"
                            outlined
                            readonly
                            hide-details
                            :placeholder="$store.state.hyundai_user.email"
                        ></v-text-field>
                    </td>
                </tr>
            </table>
        </v-sheet>

        <!-- 제출 -->
        <v-sheet class="mt-4 mb-6 d-flex justify-center">
            <v-btn
                @click="submit()"
                width="240"
                class="rounded-lg"
                large
                dark
                depressed
                color="grey"
                outlined
            >
                수정하기
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
import ProfileImage from './components/ProfileImage'

// 검증
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        ProfileImage
    },

    mixins: [validationMixin],

    validations: {
        password_prev: {
            required,
            minLength: minLength(6)
        },

        password_next: {
            minLength: minLength(6)
        },

        password_check: {
            sameAsPassword: sameAs('password_next')
        },

        nickname: {
            required
        }
    },

    data: () => ({
        nickname_list: [],

        password_prev: "",
        password_next: "",
        password_check: "",
        nickname: "",

        user: {
            type: "",
            profile_image: "",
        },
        dialog: {
            updateImage: false
        },
    }),

    computed: {
        // 현재 비밀번호 Error 문구
        password_prev_Errors () {
            const errors = []
            if (!this.$v.password_prev.$dirty) return errors
            !this.$v.password_prev.required && errors.push('현재 비밀번호를 입력해주세요')
            !this.$v.password_prev.minLength && errors.push('패스워드는 최소 6자 이상이여야합니다')
            return errors
        },

        // 신규 비밀번호 Error 문구
        password_next_Errors () {
            const errors = []
            if (!this.$v.password_next.$dirty) return errors
            !this.$v.password_next.minLength && errors.push('패스워드는 최소 6자 이상이여야합니다')
            return errors
        },

        // 신규 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.sameAsPassword && errors.push('신규 비밀번호 확인이 같지 않습니다')
            return errors
        },

        // 닉네임 Error 문구
        nickname_Errors () {
            const errors = []
            if (!this.$v.nickname.$dirty) return errors
            !this.$v.nickname.required && errors.push('닉네임을 입력해주세요')
            return errors
        }
    },

    mounted(){
        // 유저정보 불러오기
        this.load()
    },

    methods: {
        // 유저정보 불러오기
        load(){
            this.$http.post("/api/mypage/update/load", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id
                }
            }).then((res) => {
                this.user.type = res.data[0].type
                this.user.profile_image = res.data[0].profile_image
                this.nickname = res.data[0].nickname
            })
        },

        // 기존 닉네임 검색
        async loadNicknameList(){
            await this.$http.get('/api/auth/join/nickname_search')
            .then((res) => {
                var list = []
                res.data.forEach(element => {list.push(element.nickname)})
                this.nickname_list = list
            })
        },

        // 수정하기
        submit: _.debounce(async function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 기존 닉네임 검색
            await this.loadNicknameList()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                // 추가 검증
                if(this.nickname != this.$store.state.hyundai_user.nickname && this.nickname_list.includes(this.nickname))
                {
                    alert('입력하신 닉네임은 이미 존재합니다.')
                }
                else
                {
                    if (!this.$v.$invalid) {
                        this.$http.post("/api/auth/login", {
                            params: {
                                user_id: this.$store.state.hyundai_user.user_id,
                                password:this.password_prev
                            }
                        }).then((res) => {
                            if(!res.data[0]){
                                this.$http.post("/api/mypage/update/check/temp_password", {
                                    params: {
                                        user_id: this.$store.state.hyundai_user.user_id,
                                        password:this.password_prev
                                    }
                                }).then((res2) => {
                                    if(!res2.data[0]){
                                        alert("현재 비밀번호 또는 임시 비밀번호가 맞지 않습니다.")
                                    }else{
                                        this.$http.post("/api/mypage/update/password&nickname", {
                                            params: {
                                                user_id: this.$store.state.hyundai_user.user_id,
                                                password_next: this.password_next,
                                                nickname: this.nickname
                                            }
                                        }).then((res) => {
                                            if(res.data.affectedRows){
                                                alert("회원정보가 수정되었습니다.")
                                                this.password_prev = ""
                                                this.password_next = ""
                                                this.password_check = ""
                                                this.load()
                                            }
                                        })
                                    }
                                })
                            }else{
                                this.$http.post("/api/mypage/update/password&nickname", {
                                    params: {
                                        user_id: this.$store.state.hyundai_user.user_id,
                                        password_next: this.password_next,
                                        nickname: this.nickname
                                    }
                                }).then((res) => {
                                    if(res.data.affectedRows){
                                        alert("회원정보가 수정되었습니다.")
                                        this.password_prev = ""
                                        this.password_next = ""
                                        this.password_check = ""
                                        this.load()
                                    }
                                })
                            }
                        })
                    }
                }   
            }
        }, 500)
    }
}
</script>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.custom_table tr td{
    padding:0px;
}

.custom_table tr td:first-child{
    width:120px;
    font-weight:bold;
}

</style>
