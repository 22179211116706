<template>
    <div>
        <!-- 배너 (Mobile) -->
        <BannerViewAuth
            v-if="$vuetify.breakpoint.mobile"
            type="auth_mobile"
            :width="$vuetify.breakpoint.width"
            :height="$vuetify.breakpoint.width/2.7"
            title="회원가입"
        />

        <!-- 배너 (PC) -->
        <BannerViewAuth
            v-if="!$vuetify.breakpoint.mobile"
            type="auth"
            width="1920"
            height="200"
            title="회원가입"
        />

        <!-- 본문 -->
        <v-sheet class="pt-16 pb-16 rounded-10">
            <v-sheet class="px-8 py-16 mx-auto rounded-lg" width="480" outlined>
                <!-- 상단 -->
                <v-sheet color="transparent" width="400" class="mb-10 mx-auto">
                    <div class="d-flex mx-auto mb-8">
                        <v-img
                            height="140"
                            width="200"
                            contain
                            src="@/assets/logo/vertical_default.svg"
                        ></v-img>
                    </div>
                    <p class="text-center text-subtitle-1 font-weight-medium mt-2 grey--text text--darken-2">
                        회원가입
                    </p>
                </v-sheet>

                <!-- 입력 -->
                <v-sheet class="mt-10">
                    <v-text-field
                        class="mx-14 mb-3"
                        outlined
                        v-model="user_id"
                        label="아이디"
                        persistent-placeholder
                        @change="$v.user_id.$touch()"
                        @blur="$v.user_id.$touch()"
                        :error-messages="user_id_Errors"
                    >
                    </v-text-field>
                    <v-text-field
                        class="mx-14 mb-3"
                        outlined
                        v-model="password"
                        label="비밀번호"
                        persistent-placeholder
                        type="password"
                        @change="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        :error-messages="password_Errors"
                    >
                    </v-text-field>
                    <v-text-field
                        class="mx-14 mb-3"
                        outlined
                        v-model="password_check"
                        label="비밀번호 확인"
                        persistent-placeholder
                        type="password"
                        @change="$v.password_check.$touch()"
                        @blur="$v.password_check.$touch()"
                        :error-messages="password_check_Errors"
                    >
                    </v-text-field>
                    <v-text-field
                        class="mx-14 mb-3"
                        outlined
                        v-model="nickname"
                        label="닉네임"
                        persistent-placeholder
                        @change="$v.nickname.$touch()"
                        @blur="$v.nickname.$touch()"
                        :error-messages="nickname_Errors"
                    >
                    </v-text-field>
                    <v-sheet class="d-flex align-center mx-4">
                        <v-text-field
                            outlined
                            v-model="email"
                            label="이메일"
                            persistent-placeholder
                            hide-details
                        >
                        </v-text-field>
                        <p class="text-subtitle-1 mx-4 mb-1 font-weight-bold" style="color:#9E9E9E;">@</p>
                        <v-select
                            style="width:266px;"
                            outlined
                            :items="select_list.email"
                            label="도메인 선택"
                            persistent-placeholder
                            v-model="email_type"
                            hide-details
                        ></v-select>
                    </v-sheet>
                </v-sheet>

                <p
                    class="text-center text-caption mt-4 grey--text text--darken-1"
                >
                    ※ 임직원 인증을 위하여 그룹웹메일을 입력해주세요
                </p>

                <!-- 제출 -->
                <v-sheet class="mt-12 d-flex justify-center">
                    <v-btn
                        @click="submit()"
                        width="240"
                        class="rounded-lg"
                        color="grey"
                        dark
                        x-large
                        depressed
                    >
                        가입하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import BannerViewAuth from '@/components/BannerViewAuth'

// 검증
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        BannerViewAuth
    },

    mixins: [validationMixin],

    validations: {
        user_id: {
            required,
            minLength: minLength(6)
        },

        password: {
            required,
            minLength: minLength(6)
        },

        password_check: {
            required,
            sameAsPassword: sameAs('password')
        },

        nickname: {
            required
        }
    },

    data: () => ({
        // 입력값
        user_id:"",
        password:"",
        password_check:"",
        nickname:"",
        email:"",
        email_type: "",

        // 중복 체크용 목록
        user_id_list: [],
        nickname_list: [],
        email_list: [],

        // 이메일 선택목록
        select_list: {
            email: [
                'hddfs.com',
                'naver.com',
                'gmail.com',
                'hanmail.net',
                'nate.com',
                'yahoo.co.kr',
                'empase.com',
                'hotmail.com',
                'dreamwiz.com',
                'freechal.com',
                'lycos.co.kr',
                'korea.com',
                'hanmir.com',
                'paran.com'
            ]
        }
    }),

    computed: {
        // 아이디 Error 문구
        user_id_Errors () {
            const errors = []
            if (!this.$v.user_id.$dirty) return errors
            !this.$v.user_id.required && errors.push('아이디를 입력해주세요')
            !this.$v.user_id.minLength && errors.push('아이디는 최소 6자 이상이여야합니다')
            this.user_id_list.includes(this.$v.user_id.$model) && errors.push('입력하신 아이디는 이미 존재합니다.')
            return errors
        },

        // 비밀번호 Error 문구
        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            !this.$v.password.minLength && errors.push('패스워드는 최소 6자 이상이여야합니다')
            return errors
        },

        // 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.required && errors.push('비밀번호 확인을 입력해주세요')
            !this.$v.password_check.sameAsPassword && errors.push('비밀번호 확인이 같지 않습니다')
            return errors
        },

        // 닉네임 Error 문구
        nickname_Errors () {
            const errors = []
            if (!this.$v.nickname.$dirty) return errors
            !this.$v.nickname.required && errors.push('닉네임을 입력해주세요')
            this.nickname_list.includes(this.$v.nickname.$model) && errors.push('입력하신 닉네임은 이미 존재합니다.')
            return errors
        }
    },

    created () {
        // 기존 아이디 검색
        this.loadUserIdList()

        // 기존 닉네임 검색
        this.loadNicknameList()

        // 기존 이메일 검색
        this.loadEmailList()
    },

    methods: {
        // 기존 아이디 검색
        async loadUserIdList(){
            await this.$http.get('/api/auth/join/user_id_search')
            .then((res) => {
                let list = []
                res.data.forEach(element => {list.push(element.user_id)})
                this.user_id_list = list
            })
        },

        // 기존 닉네임 검색
        async loadNicknameList(){
            await this.$http.get('/api/auth/join/nickname_search')
            .then((res) => {
                var list = []
                res.data.forEach(element => {list.push(element.nickname)})
                this.nickname_list = list
            })
        },

        // 기존 이메일 검색
        async loadEmailList(){
            this.$http.get('/api/auth/join/email_search')
            .then((res) => {
                var list = []
                res.data.forEach(element => {list.push(element.email)})
                this.email_list = list
            })
        },

        // 가입하기
        submit: _.debounce(async function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 기존 아이디 검색
            await this.loadUserIdList()

            // 기존 닉네임 검색
            await this.loadNicknameList()

            // 기존 이메일 검색
            await this.loadNicknameList()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                // 추가 검증
                if(this.user_id_list.includes(this.user_id))
                {
                    alert("입력하신 아이디는 이미 존재합니다.")
                }
                else if(this.nickname_list.includes(this.nickname))
                {
                    alert('입력하신 닉네임은 이미 존재합니다.')
                }
                else if(this.email == '' || this.email_type == '')
                {
                    alert("이메일을 정상적으로 입력해주세요")
                }
                else if(this.email_list.includes(this.email + '@' + this.email_type))
                {
                    alert("입력하신 이메일은 이미 가입하신 이메일입니다.")
                }
                else
                {
                    this.$http.post('/api/auth/join/insert', {
                        params: {
                            user_id:this.user_id,
                            password:this.password,
                            nickname:this.nickname,
                            email:this.email + '@' + this.email_type,
                            status: "미인증"
                        }
                    }).then(() => {
                        alert("회원가입을 성공적으로 완료하였습니다.\n작성하신 이메일로 인증 메일이 발송되었습니다.\n메일에서 '메일 인증'버튼을 누르시면 회원가입이 완료됩니다.");
                        this.$http.post('/api/auth/email/send', {
                            params: {
                                user_id: this.user_id,
                                email: this.email + '@' + this.email_type
                            }
                        }).then(() => {
                            this.$router.push("/")
                        })
                    })
                }   
            } 
        }, 500)
    }
}
</script>
<style scoped>
/* v-text-field, v-select */
.v-text-field--outlined >>> fieldset {
    border-color: #E0E0EB;
    border-radius: 8px;
}

.v-text-field--outlined >>> label {
    color: #9E9E9E;
    font-size:16px;
    font-weight:400;
}

::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}
</style>