<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">COMMUNITY 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="admin lighten-2"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    hide-default-header
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [4, 20, 100, -1],
                        'itemsPerPageText': '페이지당 GENRE 수'
                    }"
                    no-data-text=""
                >
                    <template v-slot:header>
                        <tr>
                            <th style="width:52px;"></th>
                            <th style="width:121px;">썸네일</th>
                            <th style="width:100%">카테고리</th>
                            <th style="width:100%">게시글 제목</th>
                            <th style="width:180px;">작성자 닉네임</th>
                            <th style="width:160px">댓글 수</th>
                            <th style="width:160px">좋아요 수</th>
                            <th style="width:160px">조회수</th>
                            <th style="width:240px">작성일자</th>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio
                                    class="ml-1"
                                    hide-details
                                    dense
                                    :value="item"
                                    @change="selectedItem = item"
                                ></v-radio>
                            </td>
                            <td>
                                <!-- 썸네일 -->
                                <v-img
                                    width="105"
                                    height="75"
                                    style="border-radius: 5px 0 0 5px;"
                                    :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail_main.png')"
                                ></v-img>
                            </td>
                            <td class="px-3">
                                <v-select
                                    hide-details
                                    outlined
                                    dense
                                    :items="select_list.category"
                                    :placeholder="select_list.category.includes(item.category)? '':item.category"
                                    v-model="item.category"
                                    @change="updateItemCategory(item)"
                                ></v-select>
                            </td>
                            <td>{{item.title}}</td>
                            <td>{{item.nickname}}</td>
                            <td>{{item.comment_count + item.sub_comment_count}}</td>
                            <td>{{item.like_count}}</td>
                            <td>{{item.view_count}}</td>
                            <td>{{new Date(item.created).toLocaleString()}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4 normalize"
                    disabled
                    large
                >
                    선택항목: {{selectedItem.user_id}}
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="admin lighten-2"
                    large
                    dark
                    depressed
                    @click="copyItemOpen()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    게시글 복사하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="admin lighten-2"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    게시글 삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 게시글 복사하기 (dialog) -->
        <v-dialog
            v-model="dialog.copy"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    게시글 복사하기
                </p>

                <!-- 제보항목 -->
                <v-select
                    style="width:300px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    placeholder="복사할 게시판 선택"
                    :items="select_list.board"
                    v-model="copyTargetBoard"
                ></v-select>

                <!-- 버튼 -->
                <div class="d-flex justify-center">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.copy = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="copyItemSubmit()"
                    >
                        복사하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        list: [],
        selected: false,
        selectedItem: {},

        // 선택지
        select_list: {
            // 검색 타입
            search_type: [
                "작성자 닉네임",
                "게시글 제목",
                "게시글 부제목"
            ],

            board: [
                "GENRE",
                "MOOD",
                // "COMMUNITY"
            ],

            category: []
        },

        dialog: {
            copy: false
        },

        copyTargetBoard: "",

        // 검색 타입
        search_type: "작성자 닉네임",

        // 검색 키워드
        keyword: ""
    }),

    mounted(){
        this.search()

        this.loadCategoryList()
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/community/select/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data

                // 썸네일 추가
                this.matchThumbnail(this.list)
            })
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
        },

        // 카테고리 목록 불러오기
        loadCategoryList(){
            this.$http.post('/api/admin/genre/select/category', {
                params: {
                    board: "community"
                }
            }).then((res) => {
                this.select_list.category = res.data.map(e => e.category)
            })
        },

        // 카테고리 수정하기
        updateItemCategory(item){
            this.$http.post('/api/admin/genre/update/category', {
                params: {
                    category: item.category,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("해당 게시글의 카테고리가 정상적으로 수정되었습니다.")
                }
            })
        },

        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 게시글이 없습니다")
            }else{
                if(confirm("정말 해당 게시글을 삭제하겠습니까?")){
                    this.$http.post('/api/admin/community/delete', {
                        params: {
                            id: this.selectedItem.id
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            alert("해당 게시글이 정상적으로 삭제되었습니다.")
                            this.search()
                        }
                    })
                }
            }
        },

        // 복사하기 열기
        copyItemOpen(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 게시글이 없습니다")
            }else{
                this.dialog.copy = true
            }
        },

        // 복사하기 열기
        copyItemSubmit(){
            if(this.copyTargetBoard == ""){
                alert("복사할 게시판을 선택해주세요")
            }else{
                this.$http.post("/api/admin/genre/insert/copy", {
                    params: {
                        board: this.copyTargetBoard.toLowerCase(),
                        id: this.selectedItem.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("해당 게시글이 정상적으로 복사되었습니다.")
                        this.search()
                        this.dialog.copy = false
                    }
                })
            }
        },

        // 썸네일 추가
        matchThumbnail(list) {
            // <img> 태그용 정규식
            const imgReg = /<img[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
            // CustomImage 태그용 정규식 (대소문자 구분 없음)
            const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;

            list.forEach(e => {
                if (typeof e.content === 'string') {
                    const imgMatch = e.content.match(imgReg);
                    const customImgMatch = e.content.match(customImgReg);

                    if (customImgMatch) {
                        // CustomImage 태그에서 src 추출
                        e.image = customImgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if (imgMatch) {
                        // <img> 태그에서 src 추출
                        e.image = imgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                }
            });
        },
    }
}
</script>
<style scoped>
/* 버튼 대소문자 고정 해제 */
.v-btn {
    text-transform:none !important;
}
</style>