<template>
    <v-sheet
        style="margin:0 auto;"
        :width="$vuetify.breakpoint.mobile? '100%':'1200'"
    >
        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 상단 -->
            <v-sheet
                v-if="!$vuetify.breakpoint.xs"
                class="py-3 px-4 d-flex align-end"
            >
                <p
                    style="width:440px; font-size:18px;"
                    class="ma-0 text-truncate"
                >
                    <!-- 카테고리 -->
                    <font
                        v-if="category"
                        class="mr-2 grey--text text--darken-2"
                    >
                        [ {{category}} ]
                    </font>

                    <!-- 제목 -->
                    <span class="font-weight-bold">
                        {{title}}
                    </span>
                    <br/>

                    <!-- 부제목 -->
                    <v-sheet
                        v-if="subtitle"
                        class="mt-2"
                        style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                    >
                        {{subtitle}}
                    </v-sheet>
                </p>
                <v-spacer></v-spacer>

                <div>
                    <v-sheet class="mb-1 d-flex align-center justify-end">
                        <!-- 공유 -->
                        <v-btn
                            class="pa-0 mb-2 mr-4"
                            icon
                            x-small
                            @click.stop="share_open()"
                        >
                            <v-icon size="18" :color="$route.query.id == share_id? 'orange':'grey darken-2'">mdi-share-variant-outline</v-icon>
                        </v-btn>

                        <!-- 스크랩 -->
                        <v-btn
                            class="pa-0 mb-2 mr-4"
                            icon
                            x-small
                            @click.stop="scrap()"
                        >
                            <v-icon v-if="!is_scraped" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                            <v-icon v-if="is_scraped" size="18" color="orange">mdi-star</v-icon>
                        </v-btn>

                        <!-- 프로필 -->
                        <Profile
                            v-if="writer"
                            :user_id="writer"
                        />
                    </v-sheet>
                    <v-sheet class="text-caption mr-1">
                        <!-- 조회수 -->
                        <font class="mr-4">
                            조회수 {{view_count}}
                        </font>

                        <!-- 작성일자 -->
                        <font style="color:#B3B3C2;">
                            {{new Date(created).toLocaleString()}}
                        </font>
                    </v-sheet>
                </div>
            </v-sheet>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>
            <TipTapReader
                v-if="options.content"
                class="tiptap_style pa-4"
                :options="options"
            />

            <!-- 첨부파일 -->
            <p
                v-if="attachment"
                class="pl-3 mb-0 text-caption"
            >
                <v-icon small class="mb-2px mr-1" color="primary">mdi-attachment</v-icon>
                첨부파일
            </p>
            <v-sheet
                v-if="attachment"
                class="pa-3 mb-6"
            >
                <v-btn
                    depressed
                    class="py-6 rounded-10"
                    @click="download(attachment)"
                >
                    <v-icon size="20" class="mt-1 mr-2" color="primary">mdi-file</v-icon>
                    {{attachment}}
                </v-btn>
            </v-sheet>

            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 좋아요, 댓글 수 -->
            <v-sheet class="mb-2 px-2 d-flex align-center">
                <!-- 좋아요 -->
                <v-btn
                    text
                    class="mr-2 px-1"
                    v-ripple="false"
                    @click="like()"
                >
                    <v-img class="mr-2" width="20" src="@/assets/board/like_default.png"></v-img>
                    <span style="font-size:12px;">
                        좋아요 {{like_list.length}}
                    </span>
                </v-btn>
                <v-spacer></v-spacer>

                <!-- 댓글 수 -->
                <p class="mb-0 mr-5 text-caption">
                    {{comment_list.length}}개의 댓글
                </p>
            </v-sheet>

            <!-- 댓글 -->
            <Comment
                :board_name="board_name"
                :title="title"
                :writer="writer"
                @updated="loadComment()"
            />
        </v-sheet>

        <!-- 게시글 공유하기 (dialog) -->
        <v-dialog
            v-model="dialog.share"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    게시글 공유하기
                </p>

                <!-- 제보항목 -->
                <v-text-field
                    style="width:300px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    placeholder="공유할 상대의 닉네임 입력"
                    v-model="share_nickname"
                ></v-text-field>

                <!-- 버튼 -->
                <div class="d-flex justify-center">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.share = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="share_submit()"
                    >
                        공유하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import Comment from "./read/comment"
import Profile from "./read/profile"

export default {
    components: {
        TipTapReader,
        Comment,
        Profile
    },

    data: () => ({
        category: "",
        title: "",
        subtitle: "",
        created: "",
        writer: "",
        nickname: "",
        attachment: "",
        view_count: 0,
        board_name: "",

        share_id: "",
        share_nickname: "",
        dialog: {
            share: false
        },

        is_scraped: false,

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        like_list: [],
        comment_list: [],
    }),

    watch: {
        dialog: {
            deep:true,
            handler(newData) {
                if(!newData.share){
                    this.share_id = ""
                }
            }
        }
    },

    mounted(){
        if(!this.$store.state.hyundai_user.is_logined){
            alert("게시글 읽기는 로그인 후 이용가능합니다.")
        }

        // 게시글 정보 가져오기
        this.$http.post('/api/board/select/specific', {
            params: {
                id: this.$route.query.id
            }
        }).then((res) => {
            console.log(res)
            this.category = res.data[0].category
            this.title = res.data[0].title
            this.subtitle = res.data[0].subtitle
            this.options.content = res.data[0].content
            this.writer = res.data[0].writer
            this.nickname = res.data[0].nickname
            this.attachment = res.data[0].attachment
            this.view_count = res.data[0].view_count
            this.created = res.data[0].created
        })

        // 댓글 가져오기 (for 댓글 수)
        this.loadComment()

        // 조회수 증가
        this.$http.post('/api/board/update/view_count', {
            params: {
                id: this.$route.query.id
            }
        })

        // 스크랩한 글인지 확인하기
        this.$http.post('/api/board/scrap/select/isScraped', {
            params: {
                user_id: this.$store.state.hyundai_user.user_id,
                post_id: this.$route.query.id
            }
        }).then((res) => {
            if(res.data.length){
                this.is_scraped = true
            }else{
                this.is_scraped = false
            }
        })

        // 추천한 사람 리스트 가져오기
        this.loadPostLikeList()
    },

    methods: {
        download(file) {
            this.$http.post("/api/image/download", {
                params: {
                    file: file
                }
            },
            {
                responseType: 'blob' // 응답 타입을 'blob'으로 설정
            }).then((res) => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data])) // 블롭 객체 생성
                const link = document.createElement('a') // 링크 엘리먼트 생성
                link.href = url // 링크에 다운로드 URL 설정
                link.setAttribute('download', file) // 파일 이름 설정
                document.body.appendChild(link) // 링크를 body에 추가
                link.click() // 클릭 이벤트 발생
                document.body.removeChild(link) // 링크 제거
                window.URL.revokeObjectURL(url) // 사용한 URL 해제
            })
        },

        // 댓글 가져오기 (for 댓글 수)
        loadComment(){
            this.$http.post('/api/board/comment/select', {
                params: {
                    post_id:this.$route.query.id
                }
            }).then((res) => {
                this.comment_list = res.data
            })
        },

        // 추천한 사람 리스트 가져오기
        loadPostLikeList(){
            this.$http.post('/api/board/select/like_list', {
                params: {
                    post_id: this.$route.query.id
                }
            }).then((res) => {
                this.like_list = res.data.map(a => a.user_id)
            })
        },

        // 추천
        like: _.debounce(function(){
            if(!this.$store.state.hyundai_user.is_logined)
            {
                alert("게시글 추천은 로그인 이후 가능합니다.")
            }
            else if(this.$store.state.hyundai_user.user_id == this.writer)
            {
                alert('자신의 글에 추천을 할 수 없습니다.')
            }
            else
            {
                if(this.like_list.includes(this.$store.state.hyundai_user.user_id))
                {
                    alert("이미 추천한 게시글입니다.")
                }
                else
                {
                    this.$http.post('/api/board/insert/like', {
                        params: {
                            post_id: this.$route.query.id,
                            user_id: this.$store.state.hyundai_user.user_id
                        }
                    }).then(() => {
                        this.loadPostLikeList()
                    })
                }
            }   
        }, 200),

        // 공유하기 열기
        share_open(){
            this.share_id = this.$route.query.id
            this.dialog.share = true
        },

        // 공유하기 제출
        share_submit(){
            if(this.share_nickname == ""){
                alert("공유할 대상의 닉네임을 입력해주세요")
            }else{
                this.$http.post("/api/board/share/checkNickname", {
                    params: {
                        nickname: this.share_nickname
                    }
                }).then((res) => {
                    if(!res.data.length){
                        alert("입력하신 닉네임에 해당하는 사용자를 찾지 못하였습니다.\n닉네임을 다시 확인해주세요.")
                    }else if(res.data[0].user_id == this.$store.state.hyundai_user.user_id){
                        alert("자신에게 게시글 공유하기를 할 수 없습니다.")
                    }else{
                        this.$http.post("/api/board/share/message/insert", {
                            params: {
                                sender: this.$store.state.hyundai_user.user_id,
                                receiver: res.data[0].user_id,
                                post_id: this.share_id
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                alert("해당 게시글이 상대에게 공유되었습니다.")
                                this.dialog.share = false
                            }
                        })
                    }
                })
            }
        },

        // 스크랩하기
        scrap(){
            if(!this.is_scraped){
                this.$http.post("/api/board/insert/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: this.$route.query.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.is_scraped = true
                    }
                })
            }else{
                this.$http.post("/api/board/delete/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: this.$route.query.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.is_scraped = false
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}

.v-btn::before {
    background-color: transparent;
}
</style>