<template>
    <!-- 본문 -->
    <v-sheet
        :width="$vuetify.breakpoint.mobile? '100%':1200"
        class="mx-auto pt-6 pb-16 px-5"
    >
        <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile? 12:6" class="px-6">
                <!-- 인기 콘텐츠 제목 -->
                <div class="d-flex justify-space-between">
                    <v-sheet>
                        <p class="text-h6 mb-1">
                            인기 콘텐츠 TOP3
                        </p>
                        <p class="text-subtitle-2 font-weight-regular grey--text text--darken-1">
                            지금 가장 인기있는 콘텐츠를 읽어보세요.
                        </p>
                    </v-sheet>
                    <v-btn
                        text
                        class="text-subtitle-2 grey--text pl-4 pr-2"
                        style="letter-spacing:-0.5px !important;"
                        to="/genre?type=list"
                    >
                        콘텐츠 모두보기
                        <v-icon size="20" class="ml-1 mt-2px">mdi-chevron-right</v-icon>
                    </v-btn>
                </div>

                <!-- 인기 콘텐츠 목록 -->
                <v-card
                    v-for="item in contents_list" :key="item.id"
                    class="mb-4 d-flex rounded-5"
                    style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                    @click="read(item)"
                >
                    <!-- 썸네일 -->
                    <v-img
                        width="210"
                        height="150"
                        style="border-radius: 5px 0 0 5px;"
                        :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail_main.png')"
                    ></v-img>

                    <!-- 텍스트 -->
                    <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                        <div class="d-flex justify-space-between mb-3">
                            <v-sheet width="230">
                                <!-- 제목 -->
                                <div
                                    class="text-subtitle-1 font-weight-medium mb-1 pr-2"
                                    style="white-space:pre-line; height:44px; width:240px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                >
                                    {{item.title}}
                                </div>

                                <!-- 부제목 -->
                                <v-sheet
                                    class="mb-1"
                                    style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                >
                                    {{item.subtitle}}
                                </v-sheet>
                            </v-sheet>

                            <v-sheet>
                                <!-- 공유 -->
                                <v-btn icon @click.stop="share_open(item)" style="margin-top:-8px; margin-right:-8px;">
                                    <v-icon size="18" :color="item.id == share_id? 'orange':'grey darken-2'">mdi-share-variant-outline</v-icon>
                                </v-btn>

                                <!-- 스크랩 -->
                                <v-btn icon @click.stop="scrap(item)" style="margin-top:-8px; margin-right:-12px;">
                                    <v-icon v-if="item.is_scraped==0" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                                    <v-icon v-if="item.is_scraped!=0" size="18" color="orange">mdi-star</v-icon>
                                </v-btn>
                            </v-sheet>
                        </div>

                        <!-- 닉네임 & 조회수 -->
                        <v-sheet class="d-flex align-center text-caption">
                            {{item.nickname}}ㆍ조회수 {{item.view_count}}
                        </v-sheet>
                    </v-sheet>
                </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile? 12:6" class="px-6">
                <!-- 커뮤니티 인기글 제목 -->
                <div class="d-flex justify-space-between">
                    <v-sheet>
                        <p class="text-h6 mb-1">
                            커뮤니티 인기글
                        </p>
                        <p class="text-subtitle-2 font-weight-regular grey--text text--darken-1">
                            지금 가장 인기있는 글을 읽어보세요.
                        </p>
                    </v-sheet>
                    <v-btn
                        text
                        class="text-subtitle-2 grey--text pl-4 pr-2"
                        style="letter-spacing:-0.5px !important;"
                        to="/community?type=list"
                    >
                        콘텐츠 모두보기
                        <v-icon size="20" class="ml-1 mt-2px">mdi-chevron-right</v-icon>
                    </v-btn>
                </div>

                <!-- 커뮤니티 인기글 목록 -->
                <v-card
                    v-for="item in hot_community_list" :key="item.id"
                    class="mb-4 d-flex rounded-5"
                    style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                    @click="read(item)"
                >
                    <!-- 썸네일 -->
                    <v-img
                        width="210"
                        height="150"
                        style="border-radius: 5px 0 0 5px;"
                        :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail_main.png')"
                    ></v-img>

                    <!-- 텍스트 -->
                    <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                        <div class="d-flex justify-space-between mb-3">
                            <v-sheet width="230">
                                <!-- 제목 -->
                                <div
                                    class="text-subtitle-1 font-weight-medium mb-1 pr-2"
                                    style="white-space:pre-line; height:44px; width:240px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                >
                                    {{item.title}}
                                </div>

                                <!-- 부제목 -->
                                <v-sheet
                                    class="mb-1"
                                    style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                >
                                    {{item.subtitle}}
                                </v-sheet>
                            </v-sheet>

                            <v-sheet>
                                <!-- 공유 -->
                                <v-btn icon @click.stop="share_open(item)" style="margin-top:-8px; margin-right:-8px;">
                                    <v-icon size="18" :color="item.id == share_id? 'orange':'grey darken-2'">mdi-share-variant-outline</v-icon>
                                </v-btn>

                                <!-- 스크랩 -->
                                <v-btn icon @click.stop="scrap(item)" style="margin-top:-8px; margin-right:-12px;">
                                    <v-icon v-if="item.is_scraped==0" size="18" color="grey darken-2">mdi-star-outline</v-icon>
                                    <v-icon v-if="item.is_scraped!=0" size="18" color="orange">mdi-star</v-icon>
                                </v-btn>
                            </v-sheet>
                        </div>

                        <!-- 닉네임 & 조회수 -->
                        <v-sheet class="d-flex align-center text-caption">
                            {{item.nickname}}ㆍ조회수 {{item.view_count}}
                        </v-sheet>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>
        
        <!-- 게시글 공유하기 (dialog) -->
        <v-dialog
            v-model="dialog.share"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-16 pt-8 pb-6">
                <!-- 제목 -->
                <p class="mb-6 font-weight-bold text-center" style="font-size:24px;">
                    게시글 공유하기
                </p>

                <!-- 제보항목 -->
                <v-text-field
                    style="width:300px;"
                    class="rounded-lg mx-auto"
                    outlined
                    dense
                    placeholder="공유할 상대의 닉네임 입력"
                    v-model="share_nickname"
                ></v-text-field>

                <!-- 버튼 -->
                <div class="d-flex justify-center">
                    <!-- 취소 -->
                    <v-btn
                        width="100"
                        depressed
                        color="grey lighten-3"
                        class="font-weight-bold rounded-10 mr-3"
                        @click="dialog.share = false"
                    >
                        취소
                    </v-btn>

                    <!-- 제출 -->
                    <v-btn
                        width="100"
                        dark
                        depressed
                        class="font-weight-bold rounded-10"
                        @click="share_submit()"
                    >
                        공유하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        contents_list: [],
        hot_community_list: [],

        share_id: "",
        share_nickname: "",
        dialog: {
            share: false
        },

        first_article: "",
        second_article: "",
        third_article: "",

        first_article2: "",
        second_article2: "",
        third_article2: "",
    }),
    
    mounted(){
        // 목록
        this.load()
    },

    watch: {
        dialog: {
            deep:true,
            handler(newData) {
                if(!newData.share){
                    this.share_id = ""
                }
            }
        }
    },

    methods: {
        // 목록
        load(){
            // 인기 콘텐츠 불러오기
            this.$http.post("/api/admin/home/hot_contents/select")
            .then((res) => {
                console.log(res)
                this.first_article = res.data[0].first_article
                this.second_article = res.data[0].second_article
                this.third_article = res.data[0].third_article

                console.log(this.extractId(this.first_article))

                this.$http.post("/api/admin/home/hot_contents/specific", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        id: this.extractId(this.first_article)
                    }
                }).then((res) => {
                    this.contents_list = [...this.contents_list, ...res.data]
                    this.$http.post("/api/admin/home/hot_contents/specific", {
                        params: {
                            user_id: this.$store.state.hyundai_user.user_id,
                            id: this.extractId(this.second_article)
                        }
                    }).then((res) => {
                        this.contents_list = [...this.contents_list, ...res.data]
                        this.$http.post("/api/admin/home/hot_contents/specific", {
                            params: {
                                user_id: this.$store.state.hyundai_user.user_id,
                                id: this.extractId(this.third_article)
                            }
                        }).then((res) => {
                            this.contents_list = [...this.contents_list, ...res.data]

                            // 썸네일 추가
                            this.matchThumbnail(this.contents_list)
                        })
                    })
                })
            })

            // 커뮤니티 인기글 TOP 3
            this.$http.post("/api/admin/home/hot_community/select")
            .then((res) => {
                console.log(res)
                this.first_article2 = res.data[0].first_article
                this.second_article2 = res.data[0].second_article
                this.third_article2 = res.data[0].third_article

                console.log(this.extractId(this.first_article2))

                this.$http.post("/api/admin/home/hot_community/specific", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        id: this.extractId(this.first_article2)
                    }
                }).then((res) => {
                    this.hot_community_list = [...this.hot_community_list, ...res.data]
                    this.$http.post("/api/admin/home/hot_community/specific", {
                        params: {
                            user_id: this.$store.state.hyundai_user.user_id,
                            id: this.extractId(this.second_article2)
                        }
                    }).then((res) => {
                        this.hot_community_list = [...this.hot_community_list, ...res.data]
                        this.$http.post("/api/admin/home/hot_community/specific", {
                            params: {
                                user_id: this.$store.state.hyundai_user.user_id,
                                id: this.extractId(this.third_article2)
                            }
                        }).then((res) => {
                            this.hot_community_list = [...this.hot_community_list, ...res.data]

                            // 썸네일 추가
                            this.matchThumbnail(this.hot_community_list)
                        })
                    })
                })
            })
        },

        // 썸네일 추가
        matchThumbnail(list) {
            // <img> 태그용 정규식
            const imgReg = /<img[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
            // CustomImage 태그용 정규식 (대소문자 구분 없음)
            const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;

            list.forEach(e => {
                if (typeof e.content === 'string') {
                    const imgMatch = e.content.match(imgReg);
                    const customImgMatch = e.content.match(customImgReg);

                    if (customImgMatch) {
                        // CustomImage 태그에서 src 추출
                        e.image = customImgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if (imgMatch) {
                        // <img> 태그에서 src 추출
                        e.image = imgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                }
            });
        },

        // 게시글로 이동
        read(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 읽기는 로그인 후 이용가능합니다.")
            }else{
                this.$router.push("/"+item.board + "?type=read&id=" + item.id)
            }
        },

        // 스크랩하기
        scrap(item){
            if(item.is_scraped == 0){
                this.$http.post("/api/board/insert/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }else{
                this.$http.post("/api/board/delete/scrap", {
                    params: {
                        user_id: this.$store.state.hyundai_user.user_id,
                        post_id: item.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }
        },

        // 공유하기 열기
        share_open(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 공유는 로그인 후 이용가능합니다.")
            }else{
                this.share_id = item.id
                this.dialog.share = true
            }
        },

        // 공유하기 제출
        share_submit(){
            if(this.share_nickname == ""){
                alert("공유할 대상의 닉네임을 입력해주세요")
            }else{
                this.$http.post("/api/board/share/checkNickname", {
                    params: {
                        nickname: this.share_nickname
                    }
                }).then((res) => {
                    if(!res.data.length){
                        alert("입력하신 닉네임에 해당하는 사용자를 찾지 못하였습니다.\n닉네임을 다시 확인해주세요.")
                    }else if(res.data[0].user_id == this.$store.state.hyundai_user.user_id){
                        alert("자신에게 게시글 공유하기를 할 수 없습니다.")
                    }else{
                        this.$http.post("/api/board/share/message/insert", {
                            params: {
                                sender: this.$store.state.hyundai_user.user_id,
                                receiver: res.data[0].user_id,
                                post_id: this.share_id
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                alert("해당 게시글이 상대에게 공유되었습니다.")
                                this.dialog.share = false
                            }
                        })
                    }
                })
            }
        },

        // id값 추출
        extractId(url) {
            const regex = /id=(\d+)/
            const matches = url.match(regex)

            if (matches && matches.length > 1) {
                return matches[1]
            } else {
                return null // 혹은 적절한 에러 처리
            }
        },
    }
}
</script>